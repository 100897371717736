const companySize = (size, type = "large") => defs[size.toLowerCase()] ?? defs.unknown

const defs = {
  micro: "less than 10",
  small: "10 to 50",
  medium: "50 to 250",
  large: "more than 250",
  unknown: "unknown",
}

export default companySize
