import L from "leaflet";
import booleanIntersects from "@turf/boolean-intersects";

const geoJSONBounds = (bounds, geoJSON) => {
  const box = []
  box.push(bounds.getSouthWest())
  box.push(bounds.getSouthEast())
  box.push(bounds.getNorthEast())
  box.push(bounds.getNorthWest())

  const finalBox = L.polygon(box).toGeoJSON()

  const within = geoJSON.features.filter((feature) => {
    try {
      return booleanIntersects(finalBox, feature)
    } catch (e) {
      console.log(e)
      return false
    }
    // return true
  })

  return {
    ...geoJSON,
    features: within,
  }
}

export default geoJSONBounds
