import React from 'react'
import {
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
} from '@coreui/react'

class GenericErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, data: {} }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, data: error }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ data: errorInfo })
  }

  render() {
    if (this.state.hasError) {
      return (
        <CCard>
          <CCardHeader>Error caught</CCardHeader>
          <CCardBody>
            <p>
              An unexpected error occurred. Please reload the page. If you continue to see this
              error after reloading, please contact an adminstrator.
            </p>

            <p>
              <CButton color={'primary'} onClick={() => window.location.reload()}>
                Reload
              </CButton>
            </p>
            <CAccordion>
              <CAccordionItem>
                <CAccordionHeader>Error Detail</CAccordionHeader>
                <CAccordionBody>
                  <pre>{this.state.data.componentStack ?? ''}</pre>
                </CAccordionBody>
              </CAccordionItem>
            </CAccordion>
          </CCardBody>
        </CCard>
      )
    }
    return this.props.children
  }
}

export default GenericErrorBoundary
