import {createSlice} from "@reduxjs/toolkit";

const initialState = {}

export const areaGroupSlice = createSlice({
  name: "area_group",
  initialState,
  reducers: {}
})

export default areaGroupSlice.reducer
