import React from 'react'
import { CCard, CCardBody, CCardImage, CCardTitle, CCol, CRow } from '@coreui/react-pro'
import { Link } from 'react-router-dom'
import analysisScreen from './images/analysis-screen.png'
import territoryScreen from './images/territory-screen.png'
import { CBadge, CCardText } from '@coreui/react'
import Permissions from '../Permissions'

const PlanningHome = () => {
  return (
    <>
      <CRow>
        <CCol xs={12} xl={6} xxl={4}>
          <CCard className={"mb-2"}>
            <CRow>
              <CCol md={6} lg={4}>
                <Link to={'territories'}>
                  <CCardImage src={territoryScreen} />
                </Link>
              </CCol>
              <CCol md={6} lg={8}>
                <CCardBody>
                  <CCardTitle>
                    <Link to={'territories'} style={{ textDecoration: 'none' }}>
                      Territory Manager{' '}
                      <CBadge color={'info'} size={'sm'}>
                        New!
                      </CBadge>
                    </Link>
                  </CCardTitle>
                  <CCardText>
                    View and edit territories for your sales and marketing team.
                  </CCardText>
                </CCardBody>
              </CCol>
            </CRow>
          </CCard>
        </CCol>
        <Permissions roles={['maps_datamanager_view', 'planning_analysis']} hide>
          <CCol xs={12} xl={6} xxl={4}>
            <CCard className={"mb-2"}>
              <CRow>
                <CCol md={6} lg={4}>
                  <Link to={'regional-analysis'}>
                    <CCardImage src={analysisScreen} />
                  </Link>
                </CCol>
                <CCol md={6} lg={8}>
                  <CCardBody>
                    <CCardTitle>
                      <Link to={'regional-analysis'} style={{ textDecoration: 'none' }}>Regional Analysis</Link>
                    </CCardTitle>
                    <CCardText>
                      Visualise and analyse clinic demand and potential markets.
                    </CCardText>
                  </CCardBody>
                </CCol>
              </CRow>
            </CCard>
          </CCol>
        </Permissions>
      </CRow>
    </>
  )
}

export default PlanningHome
