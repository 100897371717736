import React, {useEffect, useState} from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import {Bar} from 'react-chartjs-2'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  indexAxis: 'y',

  plugins: {
    legend: {
      display: false
    }
  },
  scales: {
    x: {
      suggestedMax: 50
    }
  }
}

const TopThreeTreatments = ({treatments = []}) => {

  const [data, setData] = useState({
    labels: [],
    datasets: []
  })

  useEffect(() => {
    const l = treatments.map((treatment) => treatment.name)
    const v = treatments.map((t) => t.value)
    setData({
      labels: l,
      datasets: [{
        // label: "Most Popular Treatments",
        data: v,
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      }]
    })
  }, [treatments])

  return <>
    <Bar data={data} options={options} />
  </>
}

export default TopThreeTreatments
