import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import Tabs from './Components/Tabs'

const Planning = () => {

  return (
    <div className={'position-relative'}>
      <div className={'map-page__header'} id={'map-tools-header'}>
        <div className={'map-page__tabs'}>
          <Tabs />
        </div>
      </div>
      <div>
        <Outlet />
      </div>
    </div>
  )
}

export default Planning
