export const colourFromPop = (d = 0) => {
  return d <= 0 ? '#bbb' :
    d > 0.9 ? 'hsl(194,36%,38%)' :
    d > 0.8 ? 'hsl(179,42%,42%)' :
    d > 0.7 ? 'hsl(164,49%,47%)' :
    d > 0.6 ? 'hsl(149,56%,52%)' :
    // d > 0.5 ? 'hsl(134,63%,56%)' :
    d > 0.5 ? 'hsl(119,70%,61%)' :
    // d > 0.3 ? 'hsl(104,77%,66%)' :
    d > 0.4 ? 'hsl(89,84%,70%)' :
    d > 0.3 ? 'hsl(74,91%,75%)' :
    'hsl(60,98%,80%)'
  // if(type === 'total') {
  //   return d === -1 ? '#bbb' :
  //     d > 0.9 ? '#800026' :
  //     d > 0.7  ? '#BD0026' :
  //       d > 0.6  ? '#E31A1C' :
  //         d > 0.5  ? '#FC4E2A' :
  //           d > 0.4   ? '#FD8D3C' :
  //             d > 0.3   ? '#FEB24C' :
  //               d > 0.1   ? '#FED976' :
  //                 '#FFEDA0';
  // }
  // return d === -1 ? "#bbb" :
  //   d > 0.8 ? '#800026' :
  //     d > 0.4  ? '#BD0026' :
  //       d > 0.3  ? '#E31A1C' :
  //         d > 0.08  ? '#FC4E2A' :
  //           d > 0.06   ? '#FD8D3C' :
  //             d > 0.04   ? '#FEB24C' :
  //               d > 0.02   ? '#FED976' :
  //                 '#FFEDA0';
}

/*
 return d > 1000 ? '#800026' :
           d > 500  ? '#BD0026' :
           d > 200  ? '#E31A1C' :
           d > 100  ? '#FC4E2A' :
           d > 50   ? '#FD8D3C' :
           d > 20   ? '#FEB24C' :
           d > 10   ? '#FED976' :
                      '#FFEDA0';
 */

export const median = (nums = [1,2,3,4]) => {
  if(nums.length === 0) {
    return 0
  }
  nums.sort((a,b) => a - b)
  const midpoint = Math.floor(nums.length / 2)
  let median = 0
  if(nums.length % 2 === 1) {
    median = nums[midpoint]
  } else {
    median = (nums[midpoint - 1] + nums[midpoint]) / 2
  }

  return median
}

export const percentile = (pc = 25, nums = []) => {
  if(nums.length === 0) {
    return 0
  }

  nums.sort((a,b) => a - b)
  const i = (pc / 100) * nums.length
  return i

}
