import React, { useContext, useEffect, useState } from 'react'
import { featureCollection } from '@turf/helpers'
import {GeoJSONDataContext, GeoJSONDispatchContext} from '../../../../../../contexts/geojson-data'
import { GeoJSON } from 'react-leaflet'

const CreatedGroups = () => {
  const {
    groups,
    loading,
    displayGroups,
    selectedAreaIds,
    loadUserAreas,
    liveGroupId,
  } = useContext(GeoJSONDataContext)
  const geoDispatch = useContext(GeoJSONDispatchContext)
  const [geoJSON, setGeoJSON] = useState({ features: [] })

  useEffect(() => {
    if (!loading) {
      const userAreas = loadUserAreas()
      geoDispatch('SET_GEOJSON', { region_group: userAreas })
      const idToFilter = `REGION_GROUP/${liveGroupId.toUpperCase()}`
      const filteredAreas = {
        ...userAreas,
        features: userAreas.features.filter((feature) => feature.id !== idToFilter)
      }
      // const filteredAreas = userAreas.filter((area) => )
      setGeoJSON(filteredAreas)
    }
  }, [groups, loading, liveGroupId])

  if (!displayGroups) {
    return <></>
  }

  return (
    <>
      {geoJSON.features.length > 0 && (
        <GeoJSON
          style={{fillColor: "#ccc", fillOpacity: 0.8}}
          id={"created-shapes"}
          key={JSON.stringify({ geoJSON, groups })} data={geoJSON}
          onEachFeature={(feature, layer) => {
            const {properties: {territory_id}} = feature

            if(selectedAreaIds.includes(territory_id)) {
              layer.bringToFront()
            } else {
              layer.bringToBack()
            }

            // layer.on("click", (e) => {
            //   console.log(territory_id)
            //   geoDispatch("SET_AREA_ID", territory_id)
            // })
          }}
        />
      )}
    </>
  )
}

export default CreatedGroups
