import React from 'react'
import CIcon from '@coreui/icons-react'
import { cisHamburgerMenu } from '@coreui/icons-pro'
import { useDispatch } from 'react-redux'
import { toggleMenuVisible } from '../../../../redux/ui-state'

const NavClose = () => {
  const dispatch = useDispatch()

  return (
    <span
      className={'monitor-sidebar__toggle'}
      onClick={() => {
        dispatch(toggleMenuVisible())
      }}
    >
      <CIcon icon={cisHamburgerMenu} size={'xl'} />
    </span>
  )
}

export default NavClose
