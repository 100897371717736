import React, { useContext, useEffect, useState } from 'react'
import VisualisedTerritory from './VisualisedTerritory'
import { useMap } from 'react-leaflet'
import { useLazyQuery } from '@apollo/client'
import { REGION_AGGS_BY_IDS } from '../../../../../../graphql/queries/geo'
import TerritoryList from './TerritoryList'
import searchToTerms from '../../../../../../tools/search-to-terms'
import { GeoJSONDataContext, GeoJSONDispatchContext } from '../../../../../../contexts/geojson-data'
import regionReducer from '../../../../../../tools/region-reducer'

const TerritoryVisualiser = ({ search = {}, ...props }) => {
  const map = useMap()

  const dispatch = useContext(GeoJSONDispatchContext)
  const { liveGroupId, selectedAreaIds, groups, mapMode } = useContext(GeoJSONDataContext)

  const [filter, setFilter] = useState([])

  const [regions, setRegions] = useState([])

  const [getAggs, { loading }] = useLazyQuery(REGION_AGGS_BY_IDS, {})

  const [detailedRegions, setDetailedRegions] = useState([])

  useEffect(() => {
    const filter = searchToTerms(search)
    setFilter(filter)
  }, [search])

  useEffect(() => {
    const user_regions = selectedAreaIds.filter((id) => id.includes('REGION_GROUP'))
    const geo_areas = selectedAreaIds.filter((id) => !id.includes('REGION_GROUP'))
    const allIds = selectedAreaIds.filter((id) => !id.includes('REGION_GROUP'))

    user_regions.map((region) => {
      const [, regionId] = region.split('/')
      const group = groups.find((g) => g.id.toUpperCase() === regionId)
      if (group) {
        group.ids.forEach((id) => {
          if (!allIds.includes(id)) {
            allIds.push(id)
          }
        })
      }
    })

    // Get all the data for all the IDs found

    getAggs({ variables: { filter, ids: allIds } }).then((res) => {
      if (res && res.data) {
        const {
          data: { RegionsByID = [] },
        } = res
        const combinedUserRegions = []
        user_regions.forEach((region) => {
          const [area_type, area_code] = region.split('/')
          const group = groups.find((g) => g.id.toUpperCase() === area_code.toUpperCase())
          if (group) {
            const filteredRegion = regionReducer(
              group,
              RegionsByID.filter((region) => group.ids.includes(region.id)),
            )

            combinedUserRegions.push(filteredRegion)
          }
        })
        RegionsByID.filter((region) => geo_areas.includes(region.id)).forEach((region) =>
          combinedUserRegions.push(region),
        )

        setRegions(
          combinedUserRegions.sort((a, b) => {
            if (a.territory.label > b.territory.label) return 1
            if (a.territory.label < b.territory.label) return -1
            return 0
          }),
        )
      }
    })
  }, [filter, selectedAreaIds])

  if (selectedAreaIds.length === 0) {
    return <></>
  }

  return (
    <>
      <div className={'territory-aggs-container'}>
        <div>
          <TerritoryList
            loading={loading}
            regions={regions}
            onRemove={(id) => dispatch('SET_AREA_ID', id)}
            onSelect={(id) => {
              setDetailedRegions([id])
            }}
            clear={() => dispatch('CLEAR_AREA_IDS')}
            showCreateGroup={mapMode === "groups"}
            {...props}
          />
        </div>
        <div className={'territory-aggs-list'}>
          <React.Fragment>
            {detailedRegions.length > 0 &&
              detailedRegions.map((rid) => {
                const region = regions.find((r) => r.id === rid)
                if (!region) {
                  return <></>
                }

                return (
                  <VisualisedTerritory
                    loading={loading}
                    key={`visualiser-${region.id}`}
                    region={region}
                    onClose={() => setDetailedRegions([])}
                  />
                )
              })}
          </React.Fragment>
        </div>
      </div>
    </>
  )
}

export default TerritoryVisualiser
