const searchToTerms = (search = {}, exclude = []) => {
  const ops = search.ops ?? [""]
  const operations = ops[0].split(",")
  return Object.keys(search)
    .filter((k) => k !== "ops")
    .filter((k) => !exclude.includes(k))
    .map((k) => {
      let operation = "OR"
      let field = k
      if(k.includes(":")) {
        // built-in op
        [field,operation] = k.split(":")
        operation = operation.toUpperCase()
      } else {
        const found = operations.find((o) => o.includes(k))
        if(found) {
          const [seg,setOp] = found.split(":")
          if(setOp) {
            operation = setOp.toUpperCase()
          }
        }
      }
      return {
        field,
        values: search[k].map((v) => `${v}`),
        operation
      }
    })
    .filter((op) => op.values.length > 0)

}

export default searchToTerms
