import React, {useCallback, useEffect, useReducer, useRef, useState} from "react";
import {CollapsingTabContext, CollapsingTabDispatcher} from "./context";
import {CTab, CTabContent, CTabList, CTabPanel, CTabs} from "@coreui/react-pro";
import CIcon from "@coreui/icons-react";
import {cilChevronBottom, cilChevronBottomAlt, cilChevronTop, cilChevronTopAlt} from "@coreui/icons-pro";

const defaultState = {
  activeTab: "",
  extended: false,
}

const collapsingTabReducer = (state, {type, value}) => {

  switch (type) {
    case "SET_TAB": {

      let { activeTab: oAT, extended } = { ...state }
      let activeTab = value
      if(oAT !== value) {
        extended = true
      } else {
        extended = !extended
      }

      return {
        ...state,
        activeTab,
        extended,
      }
    }
    case "TOGGLE_EXTEND": {

      return {
        ...state,
        extended: !state.extended
      }
    }
    case "SET_EXTENDED": {
      return {
        ...state,
        extended: value
      }
    }
    default: {
      return {...state}
    }
  }
}

const CollapsingTabs = ({children, activeTab, defaultTab, tabs = [], className = "", onChange, onLoaded = (height = 0) => void 0}) => {
  const ref = useRef()
  const [state, dispatchInner] = useReducer(collapsingTabReducer, defaultState, (state) => ({
    ...state,
    activeTab: defaultTab ?? "",
    extended: false,
  }))

  const dispatch = (type, value) => dispatchInner({type, value})

  useEffect(() => {
    if(typeof onChange === "function" && typeof activeTab !== "undefined") {
      dispatch("SET_TAB", activeTab)
    }
  }, [activeTab]);

  const onChangeInner = (tab) => {
    if(typeof onChange === "function" && typeof activeTab !== "undefined") {
      onChange(tab)
      if(tab === activeTab) {
        dispatch("TOGGLE_EXTEND", {})
      }
    } else {
      dispatch("SET_TAB", tab)
    }
  }


  const onLoadedInner = () => {
    setTimeout(() => {
      onLoaded(ref.current.scrollHeight)
    }, 10)
  }

  useEffect(() => {
    if(ref && ref.current) {
      onLoadedInner()
    }
  }, [ref.current, state]);

  return <div className={`${className} collapsing-tabs`} ref={ref}>
    <CTabs
      className={`${state.extended ? "": "collapsed"}`}
      activeItemKey={state.activeTab}
      onChange={(tab) => {
        // onChangeInner(tab)
      }}
    >
      <CTabList variant={"underline-border"} layout={"fill"} >
        <button className={"nav-link tabs-toggler"} onMouseUp={() => dispatch("TOGGLE_EXTEND", undefined)}>
          <CIcon icon={state.extended ? cilChevronBottomAlt : cilChevronTopAlt} />
        </button>

        <>{tabs.map((t, id) => <CTab
          onClick={(e) => {
            onChangeInner(t.id)
          }}
          onContextMenu={(e) =>e.preventDefault() }
          key={`${t.id}-${id}`}
          itemKey={t.id}>
          {t.name}
        </CTab>)}</>
      </CTabList>
      <CTabContent><>{tabs.map((tab) => {
        const cloned = React.cloneElement(tab.content, {
          onChange: () => {
            if(tab.content?.props?.onChange && typeof tab.content.props.onChange === "function") {
              tab.content.props.onChange()
            }
            onLoadedInner()
          },
        })
        return <CTabPanel key={`panel-${tab.id}`} itemKey={tab.id}>{cloned}</CTabPanel>
      } )}</></CTabContent>
    </CTabs>
    {children}
  </div>

}

export default CollapsingTabs
