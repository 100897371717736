import React, {useCallback, useContext, useEffect} from 'react'
import Tabs from '../../Tabs'
import TerritoryList from "./TerritoryList";
import {useMap} from "react-leaflet";
import L from "leaflet";
import {useEventBus} from "../../../../../hooks/use-event-bus";
import booleanIntersects from "@turf/boolean-intersects";
import {GeoJSONDataContext, GeoJSONDispatchContext} from "../../../../../contexts/geojson-data";
import booleanContains from "@turf/boolean-contains";
import booleanCrosses from "@turf/boolean-crosses";
import booleanOverlap from "@turf/boolean-overlap";
import {useDispatch, useSelector} from "react-redux";
import {addCodes} from "../../../../../redux/territory";
const drawControl = new L.Control.Draw({
  position: 'bottomright',
  draw: {
    polyline: false,
    marker: false,
    circlemarker: false,
    circle: false,
  },
})

const visType = 'postcode_district'

const MapControls = () => {
  const map = useMap()
  const geoJSON = useSelector(({geojsonReducer}) => geojsonReducer.geoJSON)

  const dispatch = useDispatch()
  const isEditing = useSelector(({territoryReducer}) => territoryReducer.edit)
  const selectedAreaIds = useSelector(({territoryReducer}) => territoryReducer.codes)

  useEffect(() => {
    if(isEditing) {
      drawControl.addTo(map)
    }
    return () => void drawControl.remove(map)
  }, [geoJSON, isEditing]);

  useEventBus("map-actions", ({detail: {action, value}}) => {
    if(action === "flyToBounds") {
      map.flyToBounds(value)
    }
    if(action === "flyToCenter") {
      map.flyTo(value)
    }
  })


  const inTerritoryAlready = (feature) => {
    const {user_territory = {}} = geoJSON

    return user_territory.features.some((f) => {
      if(!f.properties || !f.properties.area_ids || !Array.isArray(f.properties.area_ids)) {
        return false
      }

      return f.properties.area_ids.includes(feature.id)
    })

    //
    // return user_territory.features.some((f) => booleanContains(feature, f))
  }

  useEffect(() => {

    map.on(L.Draw.Event.CREATED, (e) => {
      const json = geoJSON[visType]
      if (json) {

        const {layer} = e

        const geometry = []
        layer._latlngs[0].forEach((l) => {
          geometry.push([l.lat, l.lng])
        })
        const f = L.polygon(geometry).toGeoJSON()

        const intersections = json.features
          .filter((feature) => {
            return booleanIntersects(f, feature)
          })
          .filter((feature) => !inTerritoryAlready(feature))
          .map((feature) => feature.id)
        dispatch(addCodes(intersections))

      }
    })
    return () => {
      map.off(L.Draw.Event.CREATED)
    }
  }, [map, selectedAreaIds, geoJSON]);


  return (
    <>
    </>
  )
}

export default MapControls
