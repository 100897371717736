import React, {useContext} from "react";
import {CFormSwitch} from "@coreui/react";
import {GeoJSONDataContext, GeoJSONDispatchContext} from "../../../../../../../contexts/geojson-data";

const ShowGroups = () => {
  const { displayGroups, mapMode } = useContext(GeoJSONDataContext)
  const dispatch = useContext(GeoJSONDispatchContext)

  return <>
    <div className={'d-flex p-3'}>
      <CFormSwitch
        disabled={mapMode === "groups"}
        onChange={() => dispatch('TOGGLE_GROUP_VISIBLE')}
        checked={displayGroups}
      />{' '}
      Show Group Outlines
    </div>
  </>
}

export default ShowGroups
