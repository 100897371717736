import React, { useEffect, useState } from 'react'
import SocialData from '../../ClinicDetail/Components/SocialData'
import { EnvelopeAt, Telephone } from 'react-bootstrap-icons'

const SocialInfo = ({ id, socialData, phone }) => {
  const [hasEmail, setHasEmail] = useState(false)
  const [hasPhone, setHasPhone] = useState(false)
  const [reducedSocialData, setReducedSocialData] = useState([])
  useEffect(() => {
    const has = socialData.findIndex((i) => i.type === 'email')
    setHasEmail(has !== -1)
    setHasPhone(phone && phone.length > 0)

    const types = []

    setReducedSocialData(
      socialData.filter((d) => {
        if (!types.includes(d.type)) {
          types.push(d.type)
          return true
        }
        return false
      }),
    )
  }, [id, socialData, phone])

  return (
    <div className={'d-flex gap-2'}>
      {hasPhone && <Telephone size={'1.5rem'} />}
      {hasEmail && <EnvelopeAt size={'1.5rem'} />}
      <SocialData socialData={reducedSocialData} includeLink={false} />
    </div>
  )
}

export default SocialInfo
