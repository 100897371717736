import {configureStore} from "@reduxjs/toolkit";
import territoryReducer from "./territory"
import geojsonReducer from "./geojson"
import uiStateReducer from "./ui-state"
import areaGroupReducer from "./area-group"
export default configureStore({
  reducer: {
    areaGroupReducer,
    geojsonReducer,
    territoryReducer,
    uiStateReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  })
})
