import React, { useCallback, useContext, useEffect, useState } from 'react'
import RegionLayer from './RegionLayer'
import { SearchContext } from '../../../../../hooks/search-context'
import { useMap } from 'react-leaflet'
import { AggSearchContext, AggSearchDispatchContext } from '../../../../../contexts/agg-search'
import { GeoJSONDataContext, GeoJSONDispatchContext } from '../../../../../contexts/geojson-data'
import L from 'leaflet'
import 'leaflet-draw'
import booleanIntersects from '@turf/boolean-intersects'
import { useEventBus } from '../../../../../hooks/use-event-bus'

const drawControl = new L.Control.Draw({
  position: 'bottomright',
  draw: {
    polyline: false,
    marker: false,
    circlemarker: false,
    circle: false,
  },
})

const MapControls = () => {
  const map = useMap()

  const {search: userSearch = {}, aggregateReturns} = useContext(SearchContext)
  const searchDispatch = useContext(AggSearchDispatchContext)
  const [groupsVisible, setGroupsVisible] = useState(false)
  const {visType} = useContext(AggSearchContext)
  const {selectedAreaIds, geoJSON, isAlreadySelected, mapMode} = useContext(GeoJSONDataContext)
  const geoDispatch = useContext(GeoJSONDispatchContext)

  useEventBus("map-actions", ({detail: {action, value}}) => {
    if(action === "flyToBounds") {
      map.flyToBounds(value)
    }
  })

  const mergeAreaIDs = useCallback((ids = []) => {
    ids.filter((id) => !selectedAreaIds.includes(id))
      .forEach((id) => {
        geoDispatch("SET_AREA_ID", id)
      })

  }, [selectedAreaIds])

  useEffect(() => {
    map.off(L.Draw.Event.CREATED)
    map.on(L.Draw.Event.CREATED, (e) => {
      const json = geoJSON[visType]
      if (json) {

        const {layer} = e

        const geometry = []
        layer._latlngs[0].forEach((l) => {
          geometry.push([l.lat, l.lng])
        })
        const f = L.polygon(geometry).toGeoJSON()

        const intersections = json.features
          .filter((feature) => !isAlreadySelected(feature.id))
          .filter((feature) => {
            return booleanIntersects(f, feature)
          })
          .map((feature) => feature.id)
        mergeAreaIDs(intersections)

      }
    })
  }, [map, visType, selectedAreaIds]);

  useEffect(() => {
    drawControl.remove(map)
    if (mapMode !== "help") {
      if (mapMode === "groups") {
        drawControl.addTo(map)
        map.setZoom(9)
        map.setMinZoom(9)
        searchDispatch("SET_VIS_TYPE", "postcode_district")
      } else {
        drawControl.remove(map)
        map.setMinZoom(0)
        map.setZoom(7)
        searchDispatch("SET_VIS_TYPE", "postcode_area")
      }
    }
    return () => void drawControl.remove(map)
  }, [mapMode]);

  const onTerritoryClick = useCallback(
    (id) => {
      geoDispatch("SET_AREA_ID", id)
    },
    [selectedAreaIds],
  )

  const clearTerritories = () => {
    geoDispatch("CLEAR_AREA_IDS")
  }

  return (
    <>
      <RegionLayer
        groupsVisible={groupsVisible}
        onTerritoryClick={onTerritoryClick}
        clearTerritories={clearTerritories}
      />
    </>
  )
}

export default MapControls
