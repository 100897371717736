import React from "react";
import {CCol, CRow} from "@coreui/react-pro";
import {CFormSelect} from "@coreui/react";
import {setViewType} from "../../../../../../redux/geojson";
import {useDispatch, useSelector} from "react-redux";

const SelectViewType = ({onChange = (type) => void 0}) => {
  const dispatch = useDispatch()
  const viewType = useSelector(({geojsonReducer}) => geojsonReducer.viewType)
  return <div className={"ps-3 pe-3 pb-3"}>
    <hr/>
    <CRow className={"mt-3"}>
      <CCol>
        <h6>Selection Type</h6>
        <CFormSelect
          options={[
            {value: "postcode_district", label: "Postcode District"},
            {value: "postcode_area", label: "Postcode Area"},
          ]}
          value={viewType}
          onChange={(e) => {
            dispatch(setViewType(e.target.value))
          }}
        >

        </CFormSelect>
      </CCol>
    </CRow>
  </div>
}

export default SelectViewType
