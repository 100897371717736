import React, { useContext, useEffect, useState } from 'react'
import VisControl from './VisControl'
import SegmentControl from './SegmentControl'
import { CCol, CRow } from '@coreui/react-pro'
import { GeoJSONDataContext, GeoJSONDispatchContext } from '../../../../../../contexts/geojson-data'
import { AggSearchContext, AggSearchDispatchContext } from '../../../../../../contexts/agg-search'
import GroupControls from '../GroupControls'
import CIcon from '@coreui/icons-react'
import { cilInfo } from '@coreui/icons-pro'
import CollapsingTabs from '../../../../../UI/CollapsingTabs'
import ShowGroups from "./Components/ShowGroups";

const VisualiserControls = ({
  onChange = (type, value, search = undefined) => void 0,
  onTerritoryClick = (id) => void 0,
  selectedTerritories = [],
  onLoaded = (height = 0) => void 0
}) => {
  const segments = ['treatment_categories', 'market_classification']

  const { visType, popType, search } = useContext(AggSearchContext)
  const dispatch = useContext(AggSearchDispatchContext)
  const {groups, mapMode} = useContext(GeoJSONDataContext)
  const jsonDispatch = useContext(GeoJSONDispatchContext)

  const [regionControls, setRegionControls] = useState([])

  const data_views = [
    { label: 'Population Total', value: 'population' },
    { label: 'Population Density', value: 'population_density' },
    { label: 'Total Clinics', value: 'location_count' },
    { label: 'Treatment Types', value: 'treatment_categories' },
    { label: 'Service Classification', value: 'market_classification' },
  ]

  useEffect(() => {
    const region_controls = [
      { label: 'ITL1/NUTS1', value: 'itl1' },
      { label: 'ITL2/NUTS2', value: 'itl2' },
      { label: 'ITL3/NUTS3', value: 'itl3' },
      { label: 'Postcode Area', value: 'postcode_area' },
      { label: 'Postcode District', value: 'postcode_district' },
      // { label: 'Region Groups', value: 'region_group' },

    ]

    if(groups && groups.length > 0) {
      region_controls.push({ label: 'Region Groups', value: 'region_group' })
    }

    region_controls.push({ label: 'User Territories', value: 'user_territory' })

    setRegionControls(region_controls)
  }, [groups]);

  const tabs = [
    {
      id: 'data',
      name: 'Visualisation',
      content: (
        <>
          <CRow>
            <CCol className={'d-flex'}>
              <VisControl
                title={'Region Type'}
                type={'visType'}
                values={regionControls}
                onChange={(type, value) => {
                  dispatch('SET_VIS_TYPE', value)
                  if (value === 'region_group') {
                    jsonDispatch('SET_GROUP_VISIBILITY', false)
                  }
                }}
                selected={visType}
              ></VisControl>
              {/*<TerritorySelect*/}
              {/*  style={{padding: "0.6rem"}}*/}
              {/*  visible={visType === "user_territory"}*/}
              {/*  onClick={onTerritoryClick}*/}
              {/*  selectedTerritories={selectedTerritories}*/}
              {/*/>*/}
            </CCol>
          </CRow>
          <CRow>
            <CCol className={'d-flex'}>
              <VisControl
                title={'Visualised Statistic'}
                type={'popType'}
                values={data_views}
                onChange={(type, value) => dispatch('SET_POP_TYPE', value)}
                selected={popType}
              />

              <SegmentControl
                visible={segments.includes(popType)}
                onChange={(search) => dispatch('SET_SEARCH', search)}
                view={data_views.filter((view) => view.value === popType).pop()}
                search={search}
              />
            </CCol>
          </CRow>
        </>
      ),
    },
    { id: 'groups', name: "Area Groups", content: <GroupControls /> },
    {
      id: 'help',
      name: <CIcon icon={cilInfo} size={'lg'} />,
      content: (
        <div className={'p-3'} style={{ maxWidth: '23rem' }}>
          <p>
            From this screen you can visualise and compare information about different regions and
            areas.
          </p>
          <p>
            Clicking on areas will create a list for easy comparison. This list can then be saved as
            an "Area Group", which will display an aggregate of the regions and areas within the
            group.
          </p>
          <p>These aggregate groups can be compared like any other region.</p>
        </div>
      ),
    },
  ]

  return (
    <CollapsingTabs
      className={"map-page__visualiser shadow-sm"}
      tabs={tabs}
      defaultTab={'data'}
      onLoaded={onLoaded}
      onChange={(tab) => {
        jsonDispatch('SET_MAP_MODE', tab)

        if (tab !== 'help') {
          if (tab === 'groups') {
            jsonDispatch('SET_GROUP_VISIBILITY', true)
          } else {
            jsonDispatch('SET_LIVE_GROUP_ID', '')
            jsonDispatch('SET_GROUP_VISIBILITY', false)
          }
        }
      }}
    >
      <CRow>
        <CCol>
          <ShowGroups />
        </CCol>
      </CRow>
    </CollapsingTabs>
  )


  // return (
  //   <div
  //     // ref={(ref) => {
  //     //   if (!ref) {
  //     //     return void 0
  //     //   }
  //     //   L.DomEvent.disableClickPropagation(ref).disableScrollPropagation(ref)
  //     // }}
  //     className={'visualiser-controls'}
  //     style={{ cursor: 'default' }}
  //   >
  //     <CTabs
  //       activeItemKey={mapMode}
  //       onChange={(tab) => {
  //         jsonDispatch('SET_MAP_MODE', tab)
  //
  //         if (tab !== 'help') {
  //           if (tab === 'groups') {
  //             jsonDispatch('SET_GROUP_VISIBILITY', true)
  //           } else {
  //             jsonDispatch('SET_LIVE_GROUP_ID', '')
  //             jsonDispatch('SET_GROUP_VISIBILITY', false)
  //           }
  //         }
  //       }}
  //     >
  //       <CTabList variant={'underline-border'} layout={'fill'}>
  //         <CTab itemKey={'data'}>Visualisation</CTab>
  //         <CTab itemKey={'groups'}>Area Groups</CTab>
  //         <CTab itemKey={'help'}>
  //           <CIcon icon={cilInfo} size={'lg'} />{' '}
  //         </CTab>
  //       </CTabList>
  //       <CTabContent>
  //         <CTabPanel itemKey={'help'}>
  //           <div className={'p-3'} style={{maxWidth: "23rem"}}>
  //             <p>
  //               From this screen you can visualise and compare information about different regions
  //               and areas.
  //             </p>
  //             <p>
  //               Clicking on areas will create a list for easy comparison. This list can then be
  //               saved as an "Area Group", which will display an aggregate of the regions and areas
  //               within the group.
  //             </p>
  //             <p>These aggregate groups can be compared like any other region.</p>
  //           </div>
  //         </CTabPanel>
  //         <CTabPanel itemKey={'data'}>
  //           <CRow>
  //             <CCol className={'d-flex'}>
  //               <VisControl
  //                 title={'Region Type'}
  //                 type={'visType'}
  //                 values={regionControls}
  //                 onChange={(type, value) => {
  //                   dispatch('SET_VIS_TYPE', value)
  //                   if (value === 'region_group') {
  //                     jsonDispatch('SET_GROUP_VISIBILITY', false)
  //                   }
  //                 }}
  //                 selected={visType}
  //               >
  //
  //               </VisControl>
  //               {/*<TerritorySelect*/}
  //               {/*  style={{padding: "0.6rem"}}*/}
  //               {/*  visible={visType === "user_territory"}*/}
  //               {/*  onClick={onTerritoryClick}*/}
  //               {/*  selectedTerritories={selectedTerritories}*/}
  //               {/*/>*/}
  //             </CCol>
  //           </CRow>
  //
  //           <CRow>
  //             <CCol className={'d-flex'}>
  //               <VisControl
  //                 title={'Visualised Statistic'}
  //                 type={'popType'}
  //                 values={data_views}
  //                 onChange={(type, value) => dispatch('SET_POP_TYPE', value)}
  //                 selected={popType}
  //               />
  //
  //               <SegmentControl
  //                 visible={segments.includes(popType)}
  //                 onChange={(search) => dispatch('SET_SEARCH', search)}
  //                 view={data_views.filter((view) => view.value === popType).pop()}
  //                 search={search}
  //               />
  //             </CCol>
  //           </CRow>
  //         </CTabPanel>
  //         <CTabPanel itemKey={'groups'}>
  //           <GroupControls />
  //         </CTabPanel>
  //       </CTabContent>
  //     </CTabs>
  //

  //   </div>
  // )
}

export default VisualiserControls
