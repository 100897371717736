import React, { useEffect, useState } from 'react'
import TerritoryList from './TerritoryList'
import CollapsingTabs from '../../../../UI/CollapsingTabs'
import { useSelector } from 'react-redux'
import EditTerritory from './EditTerritory'
import CreateTerritory from './CreateTerritory'
import AreaList from './TerritoryForm/AreaList'
import {CCol, CRow} from "@coreui/react-pro";
import {CFormSelect} from "@coreui/react";
import SelectViewType from "./SelectViewType";

const TerritoryTabs = ({ setStyle = (style) => void 0 }) => {
  const [activeTab, setActiveTab] = useState('tlist')
  const [tabs, setTabs] = useState([])
  const isEditing = useSelector(({ territoryReducer }) => territoryReducer?.edit)
  const territoryId = useSelector(({ territoryReducer }) => territoryReducer.id)
  const viewType = useSelector(({geojsonReducer}) => geojsonReducer.viewType)
  useEffect(() => {
    const t = []
    if (isEditing) {
      let content = <CreateTerritory />
      if (territoryId && territoryId.length > 0) {
        content = <EditTerritory />
      }
      t.push({ name: 'Territory Details', id: 'tlist', content })
      t.push({ name: 'Areas', id: 'territoryAreas', content: <AreaList /> })
    } else {
      t.push({ name: 'Territories', id: 'tlist', content: <TerritoryList /> })
    }
    setTabs(t)
  }, [isEditing, territoryId])

  return (
    <CollapsingTabs
      className={'map-page__visualiser shadow-sm'}
      defaultTab={'tlist'}
      activeTab={activeTab}
      onChange={(tab) => {
        setActiveTab(tab)
      }}
      tabs={tabs}
      onLoaded={(height) => {
        setStyle({ '--lower-tabs-height': `${height}px` })
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'))
        }, 100)
      }}
    >
      {isEditing && <SelectViewType />}

    </CollapsingTabs>
  )
}

export default TerritoryTabs
