import React, { useContext, useState } from 'react'
import { CButton, CFormInput, CInputGroup, CPopover } from '@coreui/react'
import { GeoJSONDispatchContext } from '../../../../../../contexts/geojson-data'
import { useEventBus } from '../../../../../../hooks/use-event-bus'
import * as uuid from 'uuid'

const CreateRegionGroup = ({ regions = [], clear = () => void 0 }) => {
  const { emit } = useEventBus('toasts')
  const [visible, setVisible] = useState(false)
  const [groupName, setGroupName] = useState('')
  const dispatch = useContext(GeoJSONDispatchContext)

  const createGroup = () => {
    const ids = regions.map((region) => region.id)
    if (groupName.length === 0) {
      emit({ message: 'A region group must have a name.' })
      return
    }
    if (ids.length > 0) {
      const group = {
        name: groupName,
        ids,
        id: uuid.v4(),
      }
      // TODO check we're not adding the same area to more than one group
      dispatch('ADD_GROUP', group)
      setVisible(false)
      setGroupName('')
      clear()
    } else {
    }
  }

  return (
    <>
      <CPopover
        visible={visible}
        placement={'left'}
        trigger={[]}
        content={
          <>
            <CInputGroup size={'sm'}>
              <CFormInput
                placeholder={'Enter name'}
                value={groupName}
                onChange={(e) => setGroupName(e.target.value)}
                onKeyUp={({ key }) => {
                  if (key.toUpperCase() === 'ENTER') {
                    createGroup()
                  }
                }}
              />{' '}
              <CButton
                color={"primary"}
                onClick={() => {
                  createGroup()
                }}
              >
                Create
              </CButton>
            </CInputGroup>
          </>
        }
      >
        <CButton
          color={"primary"}
          size={'sm'}
          onClick={() => {
            setVisible(!visible)
          }}
        >
          Create Region Group
        </CButton>
      </CPopover>
    </>
  )
}

export default CreateRegionGroup
