const normaliseValue = ([min,max], [median,pc_25,pc_75], value) => {
  if(value === 0 || Number(max ?? "0") === 0) {
    return 0
  }

  const scaled = (value - median) / (Math.floor(max) - Math.floor(min))
  const norm = (scaled + 1) / 2
  if(Number.isNaN(norm)) {
    return 0
  }
  return norm
}

export default normaliseValue
