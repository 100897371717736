import React, { useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { READ_SEGMENTS } from '../../../../../../graphql/queries/search-manage'
import { SideSegment } from '../../../../../Segments'
import {setSegTag, swapSegs} from "../../../../../../tools/set-seg-tag";

const SegmentControl = ({ view, search = {}, visible = true, onChange = (filter) => void 0 }) => {
  const [segment, setSegment] = useState({ strings: [] })
  const [strings, setStrings] = useState([])
  const [selected, setSelected] = useState({ [view.value]: [] })

  const onInnerChange = (segment, tag) => {
    let {...s} = search
    s = setSegTag(s, segment, tag)
    onChange(s)
  }

  const [loadSegments, { data }] = useLazyQuery(READ_SEGMENTS, {
    variables: {
      segments: [view.value],
    },
  })

  useEffect(() => {
    if(visible) {
      const seg = view.value
      loadSegments({variables: {segments: [seg]}})
        .then(({data: {SearchSegmentData}}) => {
          if (SearchSegmentData && SearchSegmentData[seg]) {
            const strings = SearchSegmentData[seg]
            setStrings(strings)
          } else {
            setStrings([])
          }
        })
    }
  }, [view, visible]);

  useEffect(() => {
    if(visible) {
      const seg = view.value
      let title = "Select"

      setSegment({
        strings,
        name: seg,
        title,
      })

    }
  }, [strings, search, visible, view]);

  if (!visible) {
    return <></>
  }
  return (
    <div>
      <SideSegment segment={segment} search={search} onChange={onInnerChange} onClear={() => onChange({})} />
    </div>
  )
}

export default SegmentControl
