import React from "react";
import TerritoryForm from "./TerritoryForm";
import {useEventBus} from "../../../../../hooks/use-event-bus";
import {useMutation} from "@apollo/client";
import {CREATE_TERRITORY} from "../../../../../graphql/queries/territory";
import {useDispatch, useSelector} from "react-redux";
import {loadGeoJSONByType, rerollCacheBuster} from "../../../../../redux/geojson";
import {clearTerritory} from "../../../../../redux/territory";

const CreateTerritory = () => {

  const { emit } = useEventBus('toasts')
  const territory = useSelector((state) => state.territoryReducer)
  const dispatch = useDispatch()

  const [create, { loading: createLoading }] = useMutation(CREATE_TERRITORY, {
    onError() {
      emit({ type: 'error', message: e.message })
    },
    onCompleted() {
      emit({ type: 'success', message: 'Territory created!' })
      dispatch(clearTerritory())
      dispatch(rerollCacheBuster())
      dispatch(loadGeoJSONByType("user_territory"))
    },
  })

  return <>
    <TerritoryForm territory={territory} onComplete={async (variables) => create({variables})} />
  </>
}

export default CreateTerritory
