import React, {useState} from "react";
import {CButton, CModal, CModalBody, CModalHeader} from "@coreui/react-pro";
import CIcon from "@coreui/icons-react";
import {cilTrash} from "@coreui/icons-pro";
import {useApolloClient, useMutation} from "@apollo/client";
import {DELETE_TERRITORY} from "../../../../../../graphql/queries/territory";

const DeleteTerritory = ({id, onComplete}) => {
  const client = useApolloClient()
  const [visible, setVisible] = useState(false)
  const [deleteTerritory, {loading}] = useMutation(
    DELETE_TERRITORY,
    {
      variables: { id },
      onCompleted() {
        client.cache.modify({
          id: "ROOT_QUERY",
          fields: {
            search(current) {
              return {
                ...current,
                reducedSegments: undefined
              }
            }
          }
        })
        client.cache.gc()
        setVisible(false)
        onComplete()
      }
    }
  )

  return <>
    <CModal size={"sm"} visible={visible} onClose={() => setVisible(false)}>
    <CModalHeader>Confirm Delete?</CModalHeader>
  <CModalBody className={'d-flex justify-content-between'}>
    <CButton color={"success"} size={'sm'} onClick={() => deleteTerritory()}>
      Confirm
    </CButton>
    <CButton color={"primary"} variant={"outline"} size={'sm'} onClick={() => setVisible(false)}>
      Cancel
    </CButton>
  </CModalBody>
  </CModal>
    <CButton color={"danger"} size={"sm"} onClick={() => setVisible(true)}><CIcon icon={cilTrash} /></CButton>
  </>
}

export default DeleteTerritory
