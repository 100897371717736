import React from 'react'
import { NavLink } from 'react-router-dom'
import CIcon from '@coreui/icons-react'
import { cilChartDonut, cilMapAlt, cilPuzzle } from '@coreui/icons-pro'
import FeatureFlagged from '../../../Utility/FeatureFlagged'
import Permissions from '../../../Permissions'

const Tabs = ({className = 'map-page-list-link btn'}) => {
  return (
    <>
      <NavLink to={'/planning'} className={className} tole={'tab'} end>
        <CIcon icon={cilMapAlt} /> Planning Home
      </NavLink>
      <FeatureFlagged flag={'new-territory-mapper'}>
        <NavLink to={'/planning/territories'} className={className} role={'tab'} end>
          <CIcon icon={cilPuzzle} /> Territory Builder
        </NavLink>
      </FeatureFlagged>
      <Permissions roles={['maps_datamanager_view', 'planning_analysis']} hide>
        <NavLink
          to={'/planning/regional-analysis'}
          className={className}
          role={'tab'}
        >
          <CIcon icon={cilChartDonut} /> Regional Analysis
        </NavLink>
      </Permissions>
    </>
  )
}

export default Tabs
