import React, {useEffect, useState} from "react";
import {createPortal} from "react-dom";

const InPortal = ({portal = "", children, ...props}) => {
  const [el, setEl] = useState(undefined)
  useEffect(() => {
    const e = document.getElementById(portal)
    if(e) {
      setEl(e)
    }
  }, []);
  if(!el) {
    return <></>
  }
  return createPortal(children, document.getElementById(portal))
}

export default InPortal
