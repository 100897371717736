import React, { useContext, useEffect, useState } from 'react'
import { MapContainer, Pane, TileLayer, ZoomControl } from 'react-leaflet'
import MapControls from './Components/MapControls'
import { GeoJSONDataContext, GeoJSONDispatchContext } from '../../../../contexts/geojson-data'
import TerritoryShapes from './Components/TerritoryShapes'
import SelectionShapes from './Components/SelectionShapes'
import InPortal from '../../../UI/InPortal'
import TerritoryTabs from './Components/TerritoryTabs'
import { useEventBus } from '../../../../hooks/use-event-bus'
import {useDispatch, useSelector} from 'react-redux'
import { collapseMenu, expandMenu } from '../../../../redux/ui-state'
import {loadGeoJSONByType, setViewType} from '../../../../redux/geojson'
import { clearTerritory } from '../../../../redux/territory'
import SelectViewType from "./Components/SelectViewType";

const TerritoryBuilder = () => {
  const key = window._env_.MAPTILES_KEY
  const dispatch = useDispatch()
  // This will need to become a setting somehow
  // const [viewType, setViewType] = useState("postcode_area")

  const geojsonDispatch = useContext(GeoJSONDispatchContext)
  const { emit } = useEventBus('territory-refresh')
  const [activeID, setActiveID] = useState('')
  useEventBus('map-actions', ({ detail: { action, value } }) => {
    if (action === 'setActiveID') {
      setActiveID(value)
    }
  })

  const isEditing = useSelector(({territoryReducer}) => territoryReducer.edit)

  const [style, setStyle] = useState({})

  useEffect(() => {
    dispatch(loadGeoJSONByType('postcode_district'))
    dispatch(loadGeoJSONByType('postcode_area'))
    dispatch(loadGeoJSONByType('user_territory'))
    dispatch(setViewType("postcode_area"))

    geojsonDispatch('SET_LIVE_GROUP_ID', '')
    dispatch(collapseMenu())
    dispatch(clearTerritory())
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'))
    }, 300)
    return () => {
      dispatch(expandMenu())
      dispatch(clearTerritory())
    }
  }, [])

  return (
    <>
      <div className={'map-page__body'} style={style}>
        {/*{isEditing && <SelectViewType onChange={setViewType} viewType={viewType} />}*/}
        <InPortal portal={'map-tools-header'}>
          <TerritoryTabs setStyle={setStyle} />
        </InPortal>

        <MapContainer
          doubleClickZoom={false}
          style={{ margin: '0', position: 'relative', width: '100%', height: '100%' }}
          zoom={8}
          center={[54.093622, -2.892616]}
          zoomControl={false}
        >
          <ZoomControl position={'bottomright'} />
          <TileLayer
            attribution={
              '\u003ca href="https://www.maptiler.com/copyright/" target="_blank"\u003e\u0026copy; MapTiler\u003c/a\u003e \u003ca href="https://www.openstreetmap.org/copyright" target="_blank"\u003e\u0026copy; OpenStreetMap contributors\u003c/a\u003e'
            }
            ext={'png'}
            url={`https://api.maptiler.com/maps/dataviz/{z}/{x}/{y}.png?key=${key}`}
            zIndex={300}
            crossOrigin
          />
          <MapControls />
          <Pane name={'area-shapes'} style={{ zIndex: '500' }}>
            <SelectionShapes/>
          </Pane>
          <Pane name={'selected-areas'} style={{ zIndex: '550' }} />
          <Pane name={'territory-shapes'} style={{ zIndex: '600' }}>
            <TerritoryShapes activeID={activeID} />
          </Pane>
          <Pane name={'popups'} style={{ zIndex: '700' }} />
        </MapContainer>
      </div>
    </>
  )
}

export default TerritoryBuilder
