import React from "react";
import Sidebar from "./Components/Sidebar";
import {Outlet} from "react-router-dom";
import AppHeader from "../components/AppHeader";
import GenericErrorBoundary from "../components/Utility/GenericErrorBoundary";

const MinimalLayout = () => {
  return <>

    <div className={'wrapper d-flex flex-column min-vh-100 bg-light'}>
      <AppHeader collapse />
      <GenericErrorBoundary>
      <Outlet />
      </GenericErrorBoundary>
    </div>
  </>
}

export default MinimalLayout
