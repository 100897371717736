import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {CButton, CSmartPagination, CSmartTable} from "@coreui/react-pro";
import CIcon from "@coreui/icons-react";
import {cilTrash} from "@coreui/icons-pro";
import {removeCode} from "../../../../../../redux/territory";
import {clearActive, setActive} from "../../../../../../redux/geojson";
import JSONPretty from "react-json-pretty";
import {useEventBus} from "../../../../../../hooks/use-event-bus";

const itemsPerPage = 15

const AreaList = () => {
  const [page, setPage] = useState(1)
  const [pages, setPages] = useState(0)
  const areas = useSelector(({territoryReducer}) => territoryReducer.codes)
  const [activeID, setActiveID] = useState("")
  const dispatch = useDispatch()
  const [items, setItems] = useState([])
  // const activeId = useSelector(({geojsonReducer}) => geojsonReducer.activeId)
  const { emit } = useEventBus('map-actions', ({detail: {action, value}}) => {
    if(action === "setActiveID") {
      setActiveID(value)
    }
  })
  const columns = [
    {
      key: "id",
      label: "Region"
    },
    {
      key: "remove",
      label: "",
      _style: {
        width: "5rem"
      }
    }
  ]

  useEffect(() => {
    return () => void emit({action: "setActiveID", value: ""})

  }, []);

  useEffect(() => {
    if(areas) {
      const p = Math.ceil(areas.length / itemsPerPage)
      setPages(p)
      setItems(areas.map((area) => {
        const item = {
          id: area,
        }
        if(area === activeID) {
          item._selected = true
          item._props = {
            color: "primary"
          }
        }
        return item
      }))
    }
  }, [areas, activeID]);

  return <>
    <CSmartTable
      header={false}
      columns={columns}
      items={items}
      activePage={page}
      itemsPerPage={itemsPerPage}
      pagination={false}
      scopedColumns={{
        id: (item) => {
          const [,postcode] = item.id.split("/")
          return <td
            style={{cursor: "default"}}
            className={item._selected ? "selected":""}
            onClick={() => {

            }}
            onMouseEnter={() => {
              emit({action: "setActiveID", value: item.id})
            }}
            onMouseLeave={() => {
              emit({action: "setActiveID", value: ""})
            }}
          >
            {postcode}
          </td>
        },
        remove: (item) => {
          return <td style={{width: "5rem"}}>
            <CButton color={"warning"} onClick={() => dispatch(removeCode(item.id))}><CIcon icon={cilTrash} /> </CButton>
          </td>
        }
      }}
    />
    <CSmartPagination
      align={"center"}
      size={"sm"}
      activePage={page}
      pages={pages}
      onActivePageChange={(p) => setPage(p)}
    />
  </>
}

export default AreaList
