import React, {useCallback, useEffect, useState} from "react";
import {FeatureGroup, GeoJSON} from "react-leaflet";
import {v4} from 'uuid'

const Chloropleth = ({
  geoJSON,
  selectedAreaIDs,
  activeID,
  onClick = (id) => void 0,
  freeze,
  hideSelected = false,
}) => {

  const [selected, setSelected] = useState([])
  const [unselected, setUnselected] = useState([])
  const [keyHash, setKeyHash] = useState("")
  useEffect(() => {

    if(!freeze) {
      const h = v4()
      setKeyHash(h)
    }
  }, [geoJSON, freeze]);

  const style = useCallback((feature) => {
    const {
      properties: {
        id: territory_id,
        // normalisedStatValue = undefined,
      }
    } = feature

    let color = '#fff'
    let zIndex = -1
    let opacity = 1
    let fillOpacity = 0.5

    if(selectedAreaIDs.includes(territory_id)) {

      zIndex = 3000
      fillOpacity = 0.9
      color = '#4aa2fd'
    }

    let fillColor
    if(activeID === territory_id) {
      fillColor = '#4aa2fd'
    } else {
      fillColor = '#bbb' //temporary
    }

    return {
      weight: 2,
      fillOpacity,
      fillColor,
      color,
      zIndex,
      opacity,
    }
  }, [selectedAreaIDs, activeID, geoJSON])

  const onEachFeature = useCallback((feature, layer) => {
    const {
      id
    } = feature

    if(selectedAreaIDs.includes(id) || activeID === id) {
      layer.bringToFront()
    } else {
      layer.bringToBack()
    }
    layer.on('click', () => {
      console.log("CLICKED CHLOROPLETH")
      if(!freeze) {
        onClick(id)
      }
    })
    // layer.on('mouseover', () => {
    //
    // })
  }, [keyHash, freeze, hideSelected])

  return (
    <>
      <FeatureGroup
        key={keyHash}
        pane={'selected-areas'}
      >
        <>
          {geoJSON.features
            .filter((f) => {
              if(hideSelected) {
                return !selectedAreaIDs.includes(f.id)
              } else {
                return true
              }
            })
            .map((feature) => {
            return <GeoJSON
              key={`${keyHash}-${feature.id}-${freeze}`}
              data={feature}
              style={style}
              onEachFeature={(feature, layer) => onEachFeature(feature, layer)}
            />
          })}
        </>
      </FeatureGroup>
      {/*<FeatureGroup*/}
      {/*  key={`unselected-${JSON.stringify(selected)}`}*/}
      {/*  pane={'area-shapes'}*/}
      {/*>*/}
      {/*  <>*/}
      {/*    {unselected.map((feature) => {*/}
      {/*      return <GeoJSON*/}
      {/*        key={feature.id}*/}
      {/*        data={feature}*/}
      {/*        style={style}*/}
      {/*        onEachFeature={(feature, layer) => onEachFeature(feature, layer)}*/}
      {/*      />*/}
      {/*    })}*/}
      {/*  </>*/}
      {/*</FeatureGroup>*/}
    </>
  )
}

export default Chloropleth
