import React from "react";
import {useMutation} from "@apollo/client";
import {UPDATE_TERRITORY} from "../../../../../graphql/queries/territory";
import {useEventBus} from "../../../../../hooks/use-event-bus";
import TerritoryForm from "./TerritoryForm";
import {useDispatch, useSelector} from "react-redux";
import {loadGeoJSONByType, rerollCacheBuster} from "../../../../../redux/geojson";
import {clearTerritory} from "../../../../../redux/territory";

const EditTerritory = () => {
  const { emit } = useEventBus('toasts')
  const territory = useSelector((state) => state.territoryReducer)
  const dispatch = useDispatch()

  const [update, { loading: updateLoading }] = useMutation(UPDATE_TERRITORY, {
    onError(e) {
      emit({ type: 'error', message: e.message })
      // setLoading(false)
    },
    onCompleted() {

      emit({ type: 'success', message: 'Territory updated!' })
      dispatch(clearTerritory())
      dispatch(rerollCacheBuster())
      dispatch(loadGeoJSONByType("user_territory"))
    },
  })

  return <>
    <TerritoryForm territory={territory} onComplete={async (variables) => update({variables})} />
  </>
}

export default EditTerritory
