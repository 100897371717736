import React, { useEffect, useState } from 'react'
import { bbox } from '@turf/bbox'
import { CListGroup, CListGroupItem, CSpinner } from '@coreui/react'
import { useEventBus } from '../../../../../../hooks/use-event-bus'
import EditTerritory from './EditTerritory'
import DeleteTerritory from './DeleteTerritory'
import { useQuery } from '@apollo/client'
import { LIST_TERRITORY } from '../../../../../../graphql/queries/territory'
import { CButton, CCol, CRow, CSmartPagination } from '@coreui/react-pro'
import { useDispatch, useSelector } from 'react-redux'
import { loadGeoJSONByType, rerollCacheBuster } from '../../../../../../redux/geojson'
import { startEdit } from '../../../../../../redux/territory'

const TerritoryList = ({ onChange = () => void 0 }) => {
  const [activeID, setActiveID] = useState()
  const geoJSON = useSelector(({ geojsonReducer }) => geojsonReducer.geoJSON)
  const isEditing = useSelector(({ territoryReducer }) => territoryReducer.edit)

  const [page, setPage] = useState(1)
  const [filter, setFilter] = useState([])
  const [limit, setLimit] = useState(10)
  const [pages, setPages] = useState(0)

  useEventBus('territory-refresh', () => {
    refetch()
  })

  const dispatch = useDispatch()

  // const loading = true
  const {
    data: { ListTerritory: { entries, totalCount } } = {
      ListTerritory: {
        entries: [],
        totalCount: 0,
      },
    },
    loading,
    refetch,
  } = useQuery(LIST_TERRITORY, {
    variables: {
      page,
      limit,
      filter,
    },
    onCompleted() {
      if (typeof onChange === 'function') {
        onChange()
      }
    },
  })

  useEffect(() => {
    let p = 1
    if (totalCount > 0) {
      p = Math.ceil(totalCount / limit)
      if (p < 1) {
        p = 1
      }
    }
    setPages(p)
  }, [totalCount])

  const { emit } = useEventBus('map-actions', ({ detail: { action, value } }) => {
    if (action === 'setActiveID') {
      setActiveID(value)
    }
  })

  const [territoryList, setTerritoryList] = useState([])

  useEffect(() => {
    const territories = geoJSON?.user_territory ?? {}
    if (territories && territories.features) {
      const list = territories.features.map((feature) => {
        const {
          id,
          properties: { name, description },
        } = feature
        const bounds = bbox(feature)
        return {
          id,
          name,
          description,
          bounds,
        }
      })
      setTerritoryList(list)
    }
  }, [geoJSON])

  return (
    <>
      <CListGroup
        className={'position-relative territory-list__container rounded-0 border-0'}
        style={{ minHeight: '5rem' }}
      >
        {loading && (
          <span className={'territory-list__loader'}>
            <CSpinner className={'position-absolute start-50'} />
          </span>
        )}
        <>
          {entries.map((territory) => {
            const found = territoryList.find(
              (t) => t.id.toLowerCase() === `user_territory/${territory.id}`,
            )

            return (
              <CListGroupItem
                className={'d-flex gap-2'}
                active={`USER_TERRITORY/${territory.id.toUpperCase()}` === activeID}
                key={`territory-${territory.id}`}
                onMouseEnter={() => {
                  if (!isEditing) {
                    emit({
                      action: 'setActiveID',
                      value: `USER_TERRITORY/${territory.id.toUpperCase()}`,
                    })
                    // dispatch(setActive(`USER_TERRITORY/${territory.id.toUpperCase()}`))
                  }
                }}
                onMouseLeave={() => {
                  emit({ action: 'setActiveID', value: '' })
                  // dispatch(clearActive(`USER_TERRITORY/${territory.id.toUpperCase()}`))
                }}
              >
                <span
                  className={'me-auto btn border-0'}
                  onClick={() => {
                    const { bounds } = found
                    const b = [
                      [bounds[3], bounds[2]],
                      [bounds[1], bounds[0]],
                    ]
                    emit({ value: b, action: 'flyToBounds' })
                  }}
                >
                  {territory.name}
                </span>
                <EditTerritory territory={territory} />
                <DeleteTerritory
                  id={territory.id}
                  onComplete={() => {
                    dispatch(rerollCacheBuster())
                    dispatch(loadGeoJSONByType('user_territory'))
                    refetch()
                  }}
                />
              </CListGroupItem>
            )
          })}
        </>
        <CListGroupItem className={loading ? 'mt-5' : ''}>
          <CSmartPagination
            pages={pages}
            activePage={page}
            onActivePageChange={(p) => {
              onChange()
              setPage(p)
            }}
            align={'center'}
          />
        </CListGroupItem>
      </CListGroup>
      <CRow>
        <CCol className={'p-2 text-center'}>
          <CButton color={'primary'} onClick={() => dispatch(startEdit())}>
            Create New
          </CButton>
        </CCol>
      </CRow>
    </>
  )
}

export default TerritoryList
