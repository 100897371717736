import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  menuOpen: true,
  menuVisible: false,
}

export const uiStateSlice = createSlice({
  name: "ui-state",
  initialState,
  reducers: {
    setMenuOpenState(state, {payload}) {
      state.menuOpen = payload
    },
    collapseMenu(state) {
      state.menuOpen = false
    },
    expandMenu(state) {
      state.menuOpen = true
    },
    hideMenu(state) {
      state.menuVisible = false
    },
    showMenu(state) {
      state.menuVisible = true
    },
    toggleMenuVisible(state) {
      state.menuVisible = !state.menuVisible
    },
  }
})

export const {
  setMenuOpenState,
  collapseMenu,
  expandMenu,
  hideMenu,
  showMenu,
  toggleMenuVisible,
} = uiStateSlice.actions

export default uiStateSlice.reducer
