import React, {useContext, useEffect} from 'react'
import { GeoJSONDataContext, GeoJSONDispatchContext } from '../../../../../../contexts/geojson-data'
import { CButton, CFormSwitch, CListGroup, CListGroupItem } from '@coreui/react'
import { useMap } from 'react-leaflet'
import ConvertToTerritory from "./ConvertToTerritory";
import {useEventBus} from "../../../../../../hooks/use-event-bus";

const GroupControls = ({...props }) => {
  const { groups, getGroupGeoData, displayGroups, liveGroupId } = useContext(GeoJSONDataContext)
  const dispatch = useContext(GeoJSONDispatchContext)
  const {emit} = useEventBus("map-actions")
  // const map = useMap()

  const findGroup = (id) => {
    const group = getGroupGeoData(id)
    if (group.bounds) {
      const { bounds } = group
      const b = [
        [bounds[3], bounds[2]],
        [bounds[1], bounds[0]],
      ]
      // map.flyToBounds(b)
      emit({value: b, action: "flyToBounds"})
    }
  }

  // TODO move area selection/creation into context provider instead of ad-hoc.

  return (
    <>
        <CListGroup flush>
          {!groups || groups.length === 0 && <CListGroupItem>
            <p><em>You haven't created any groups yet</em></p>
            <p style={{width: "20rem"}}>Area groups can be used to make temporary comparisons over broader areas. These can be turned into territories.</p>
            <p>Groups are created by selecting two or more areas.</p>
          </CListGroupItem>}
          <>
            {groups.map((g) => (
              <CListGroupItem
                style={{ cursor: 'pointer' }}
                key={g.id}
                className={'d-flex justify-content-between'}
              >
                <span onClick={() => findGroup(g.id)}>{g.name}</span>

                <span className={"d-inline-flex gap-1"}>
                  <ConvertToTerritory group={g} />
                  {liveGroupId !== g.id && <CButton
                    size={'sm'}
                    color={"primary"}
                    onClick={() => {
                      // dispatch({ type: 'TOGGLE_GROUP_EDIT_MODE', value: g.id })
                      // setSelectedTerritories(g.ids)
                      // dispatch("REPLACE_AREA_IDS", g.ids)
                      if(liveGroupId === "") {
                        dispatch("SET_LIVE_GROUP_ID", g.id)
                      } else {
                        dispatch("SET_LIVE_GROUP_ID", "")
                      }
                    }}
                  >
                    Edit
                  </CButton>}
                  {liveGroupId === g.id && <CButton
                    size={"sm"}
                    color={"primary"}
                    variant={"outline"}
                    onClick={() => dispatch("SET_LIVE_GROUP_ID", "")}
                  >
                    Cancel Edit
                  </CButton>}
                  <CButton
                    color={"primary"}
                    size={'sm'}
                    onClick={() => dispatch('REMOVE_GROUP', g.id)}
                  >
                    Remove
                  </CButton>
                </span>
              </CListGroupItem>
            ))}
          </>
        </CListGroup>
    </>
  )
}

export default GroupControls
