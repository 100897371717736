import React, { useCallback, useEffect, useRef, useState } from 'react'
import { GeoJSON } from 'react-leaflet'
import { featureCollection } from '@turf/helpers'
import { colourFromPop } from './tools'
import normaliseValue from '../../../../../../tools/normalise-value'

// TODO bring geoJSON query into this object. Pre-cache? Map data to json as it changes.

const ChloroplethLayer = ({
  calcs = [0,0,0],
  range = [0,0],
  geoJSON = {features: []},
  regions = [],
  onClick = async (id) => void 0,
  selectedTerritoryID,
  selectedTerritories = [],
  popType,
  visType,
  ...props
}) => {
  const [combinedData, setCombinedData] = useState({})

  const geoCurrent = useRef()
  const style = useCallback(
    (feature) => {

      const {
        properties: {
          territory_id,
          normalisedStatValue,
        }
      } = feature

      let color = '#fff'
      let fillColor = '#bbb'
      if(selectedTerritoryID === territory_id) {
        fillColor = '#4aa2fd'
      } else {
        fillColor = colourFromPop(normalisedStatValue)
      }

      return {
        weight: 2,
        // fillColor: "#ccc",
        fillColor,
        opacity: 1,
        fillOpacity: selectedTerritories.includes(territory_id) ? 0.9 : 0.5,
        // dashArray: '3',
        color: selectedTerritories.includes(territory_id) ? '#4aa2fd' : '#fff',
        zIndex: selectedTerritories.includes(territory_id) ? 2000 : 0,
      }
    },
    [popType, selectedTerritories, visType, selectedTerritoryID],
  )

  useEffect(() => {

    if (regions && regions.length > 0) {
      let geoJ = {features: []}
      geoJ = geoJSON

      const featuresUnsorted = geoJ.features.map((feature) => {
        // let feature = geojson.features[0]
        let regionData = {
          statValue: 0,
          // id,
        }
        if(regions) {
          const found = regions.find((region) => region.id === feature.properties.id)
          if(found) {
            regionData = found
          }
        }

        regionData = {
          ...regionData,
          normalisedStatValue: normaliseValue(range, calcs, Number(`${regionData.statValue}` ?? "0"))
        }

        return {
          ...feature,
          properties: {
            territory_id: feature.properties.id,
            ...feature.properties,
            ...regionData,
          },
        }
      })

      // Move the selected element to the end of the array. This overcomes a current issue (either developer error or
      // library error) in which the layer.bringToFront() method doesn't actually bring the area to the front.
      // Generates an annoying flicker, so should be considered a temporary option until bringToFront can be
      // coerced into working again.
      const features = [
        ...featuresUnsorted.filter((f) => !selectedTerritories.includes(f.properties.territory_id)),
        ...featuresUnsorted.filter((f) => selectedTerritories.includes(f.properties.territory_id)),
      ]

      const f = featureCollection(features)
      setCombinedData(f)
    } else {
      setCombinedData({})
    }
  }, [geoJSON, regions, selectedTerritories, popType, range, visType, calcs, selectedTerritoryID])

  if (!combinedData || Object.keys(combinedData).length === 0) {
    return <></>
  }
  return (
    <>
      <GeoJSON
        key={JSON.stringify({combinedData, visType, popType})}
        ref={geoCurrent}
        data={combinedData}
        style={style}

        onEachFeature={(feature, layer) => {
          const {properties: {territory_id}} = feature
          if(selectedTerritories.includes(territory_id)) {
            layer.bringToFront()
          } else {
            layer.bringToBack()
          }

          layer.on("click", (e) => {
            onClick(territory_id ?? '')
          })
        }}

      ></GeoJSON>
    </>
  )
}

export default ChloroplethLayer
