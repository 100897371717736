const regionReducer = (group, regions) => {
  if(!group) {
    return []
  }
  const area_type = "REGION_GROUP"
  const area_code = group.id.toUpperCase()
  return regions
    .reduce((reg, area, _id) => {
      const populatedArea = {
        ...reg,
        ...area,
      }
      const {location_count: {count: plc}, population: {count: pc}, population_density: {count: pd}} = reg
      const {location_count: {count: lc}, population: {count: c}, population_density: {count: d}} = populatedArea

      return {
        ...reg,
        location_count: {
          ...reg.location_count,
          count: Number(plc) + Number(lc)
        },
        population: {
          ...reg.population,
          count: Number(pc) + Number(c)
        },
        // This approximates density but probably isn't accurate
        population_density: {
          ...reg.population_density,
          count: (Number(pd) + Number(d)) / 2
        }
      }
    }, {
      location_count: {
        count: 0, status: "found"
      },
      population: {count: 0, status: "found"},
      population_density: {count: 0, status: "found"},
      id: `REGION_GROUP/${group.id.toUpperCase()}`,
      territory: {
        id: `REGION_GROUP/${group.id.toUpperCase()}`,
        area_type,
        area_code,
        label: group.name
      }
    })
}

export default regionReducer
