import React, {useContext} from "react";
import {CButton} from "@coreui/react-pro";
import {GeoJSONDataContext, GeoJSONDispatchContext} from "../../../../../../contexts/geojson-data";

const UpdateRegionGroup = ({id, regions, clear}) => {

  const dispatch = useContext(GeoJSONDispatchContext)
  const updateGroup = () => {
    const group = {
      id,
      ids: regions.map((region) => region.id)
    }
    dispatch("UPDATE_GROUP", group)
  }
  return <>
    <CButton onClick={() => updateGroup()} color={"primary"} size={"sm"}>Update Group</CButton>
  </>
}

export default UpdateRegionGroup
