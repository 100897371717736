import React, { useCallback, useEffect, useState } from 'react'
import { CFormInput } from '@coreui/react'
import debounce from 'lodash.debounce'

const FreeformSegment = ({ segment, segmentSearch, onChange, ...props }) => {
  const [filteredSegs, setFilteredSegs] = useState([])
  const [value, setValue] = useState('')
  const [filter, setFilter] = useState('')
  const [error, setError] = useState('')

  const [errorClass, setErrorClass] = useState('')

  useEffect(() => {
    if (filter.length > 0) {
      setFilter(
        segment.toLocaleString.filter((string) =>
          string.toLowerCase().includes(filter.toLowerCase()),
        ),
      )
    } else {
      setFilteredSegs(segment.strings ?? [])
    }
  }, [filter, segment])

  const setValueDebounced = useCallback(
    debounce((text) => {
      const change = {
        replace: true,
        name: `${segment.name}:or`,
        value: text.toUpperCase(),
      }
      onChange([change])
    }, 500),
    [],
  )

  const onInnerChange = (val) => {
    setValue(val)
    if (segment.length && val.length > segment.length) {
      setError(segment.error ?? 'NO_ERROR')
    } else {
      setValueDebounced(val)
      setError('')
    }
  }

  return (
    <div className={'search-segment-container d-flex'}>
      <div style={{ width: '10rem' }}>
        <CFormInput
          className={error && 'is-invalid'}
          size={'sm'}
          placeholder={segment.title}
          feedbackInvalid={error}
          value={value}
          onChange={(e) => {
            onInnerChange(e.target.value)
          }}
        />
      </div>
    </div>
  )
}

export default FreeformSegment
