import React from "react";
import {CButton} from "@coreui/react-pro";
import CIcon from "@coreui/icons-react";
import {cilPencil} from "@coreui/icons-pro";
import {useDispatch} from "react-redux";
import {setTerritory} from "../../../../../../redux/territory";
import {useLazyQuery, useQuery} from "@apollo/client";
import {READ_TERRITORY} from "../../../../../../graphql/queries/territory";
import {CSpinner} from "@coreui/react";

const EditTerritory = ({id, territory, onClick = () => void 0}) => {
  const dispatch = useDispatch()

  const [readTerritory, {loading}] = useLazyQuery(READ_TERRITORY)

  return <>
    <CButton
      color={"success"}
      size={"sm"}
      title={"Edit"}
      onClick={() => {
        if(id && !territory) {
          readTerritory({ variables: { id } })
            .then(({ data: {ReadTerritory} }) => dispatch(setTerritory(ReadTerritory)))
        } else {
          dispatch(setTerritory(territory))
        }
        onClick()
      }}
    >
      {!loading && <CIcon icon={cilPencil} />}
      {loading && <CSpinner size={"sm"} /> }
    </CButton>
  </>
}

export default EditTerritory
