import React from 'react'
import { NavLink } from 'react-router-dom'
import {
  CButton,
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderDivider,
  CHeaderNav,
  CNavItem,
  CNavLink,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilAccountLogout } from '@coreui/icons-pro'
import { AppBreadcrumb } from './index'
import { AUTH_STATE, authState } from '../graphql/policies/auth'
import { clearAuthToken } from '../auth'

const AppHeader = ({ collapse }) => {
  return (
    <CHeader position="sticky" className="mb-4">
      <CContainer fluid>
        <CHeaderBrand className="mx-auto d-md-none" to="/">
          {/* <CIcon icon={logo} height={48} alt="Logo" /> */}
        </CHeaderBrand>
        <CHeaderNav className="d-none d-md-flex me-auto">
          <CNavItem>
            <CNavLink to="/" component={NavLink}>
              Rare:Monitor Dashboard
            </CNavLink>
          </CNavItem>

          {/*<CNavItem>*/}
          {/*  <CNavLink href="#">Users</CNavLink>*/}
          {/*</CNavItem>*/}
          {/*<CNavItem>*/}
          {/*  <CNavLink href="#">Settings</CNavLink>*/}
          {/*</CNavItem>*/}
        </CHeaderNav>
        <CHeaderNav>
          <CNavItem>
            <CButton
              onClick={() => {
                clearAuthToken()
                authState(AUTH_STATE.LOGGING_OUT)
              }}
              color={'primary'}
              variant={'ghost'}
            >
              <CIcon icon={cilAccountLogout} size={'lg'} title={'Log Out'} />
              <span className={'visually-hidden'}>Log Out</span>
            </CButton>
          </CNavItem>
        </CHeaderNav>
        {/*<CHeaderNav>*/}
        {/*  <CNavItem>*/}
        {/*    <CNavLink href="#">*/}
        {/*      <CIcon icon={cilBell} size="lg" />*/}
        {/*    </CNavLink>*/}
        {/*  </CNavItem>*/}
        {/*  <CNavItem>*/}
        {/*    <CNavLink href="#">*/}
        {/*      <CIcon icon={cilList} size="lg" />*/}
        {/*    </CNavLink>*/}
        {/*  </CNavItem>*/}
        {/*  <CNavItem>*/}
        {/*    <CNavLink href="#">*/}
        {/*      <CIcon icon={cilEnvelopeOpen} size="lg" />*/}
        {/*    </CNavLink>*/}
        {/*  </CNavItem>*/}
        {/*</CHeaderNav>*/}
        {/*<CHeaderNav className="ms-3">*/}
        {/*  <AppHeaderDropdown />*/}
        {/*</CHeaderNav>*/}
      </CContainer>
      {!collapse && (
        <>
          <CHeaderDivider />
          <CContainer fluid>
            <AppBreadcrumb />
          </CContainer>
        </>
      )}
    </CHeader>
  )
}

export default AppHeader
