import React, { useCallback, useContext, useEffect, useState } from 'react'
import { gql, useLazyQuery, useReactiveVar } from '@apollo/client'
import { REGIONAL_AGGS_FRAGMENT } from '../../../../../graphql/queries/geo'
import ChloroplethLayer from './ChloroplethLayer'
import { CSpinner } from '@coreui/react'
import MapKey from './MapKey'
import TerritoryVisualiser from './TerritoryVisualiser'
import { median, percentile } from './ChloroplethLayer/tools'
import { setTerritoryJSONCB } from '../../../../../graphql/policies/search'
import searchToTerms from '../../../../../tools/search-to-terms'
import { GeoJSONDataContext, GeoJSONDispatchContext } from '../../../../../contexts/geojson-data'
import { Pane, useMapEvents } from 'react-leaflet'
import L from 'leaflet'
import booleanIntersects from '@turf/boolean-intersects'
import CreatedGroups from './GroupControls/CreatedGroups'
import { AggSearchContext } from '../../../../../contexts/agg-search'
import { featureCollection } from '@turf/helpers'
import dissolve from '@turf/dissolve'
import regionReducer from "../../../../../tools/region-reducer";
import geoJSONBounds from "../../../tools/geojson-bounds";

const RegionLayer = ({
  // visType = 'itl1',
  // popType = '',
  // search = {},
  clearTerritories,
  onTerritoryClick,
  // selectedAreaIds,
  groupsVisible,
  ...props
}) => {
  const territoryJSONCB = useReactiveVar(setTerritoryJSONCB)
  const {
    loadGeoJSON,
    loading: geoLoading,
    geoJSON,
    groups,
    selectedAreaIds,
    loadUserAreas,
  } = useContext(GeoJSONDataContext)
  const geoJSONDispatch = useContext(GeoJSONDispatchContext)
  const { visType, popType, search } = useContext(AggSearchContext)

  const segments = ['treatment_categories', 'market_classification']
  const [bounds, setBounds] = useState({})

  const map = useMapEvents({
    moveend(e) {
      setBounds(e.target.getBounds())
    },
  })

  useEffect(() => {
    if (map) {
      setBounds(map.getBounds())
    }
  }, [map])

  const [regions, setRegions] = useState([])

  const [aggCalcs, setAggCalcs] = useState([0, 0, 0])
  const [aggRange, setAggRange] = useState([0, 0])
  const [boundedGeoJSON, setBoundedGeoJSON] = useState({ features: [] })

  useEffect(() => {
    if (geoJSON[visType] && bounds && Object.keys(bounds).length > 0) {
      setBoundedGeoJSON(geoJSONBounds(bounds, geoJSON[visType]))
    }
  }, [geoJSON, visType, bounds])

  const setUserAreas = useCallback(() => {
    geoJSONDispatch('START_LOADING')
    const fc = loadUserAreas()

    if (fc) {
      geoJSONDispatch('SET_GEOJSON', { region_group: fc })
    }
    geoJSONDispatch('END_LOADING')
  }, [groups, geoJSON])

  useEffect(() => {
    if (visType === 'region_group') {
      setUserAreas()
    } else {
      loadGeoJSON(visType)
    }
  }, [visType, territoryJSONCB])

  const [getAggs, { loading: dataLoading }] = useLazyQuery(
    gql`
      ${REGIONAL_AGGS_FRAGMENT}
      query AllRegionalAggs($type: String!) {
        AllRegions(type: $type) {
          ...RegionalAggsFragment
        }
      }
    `,
    {
      variables: {
        type: visType,
      },
      onError(e) {
        console.error(e)
      },
    },
  )

  useEffect(() => {
    clearTerritories()
  }, [visType])

  useEffect(() => {
    const filter = searchToTerms(search)
    let aggName = popType
    if (segments.includes(aggName)) {
      aggName = 'location_count'
    }

    let q

    if (visType === 'region_group') {
      const getIds = Array.from(
        new Set(
          [...groups].flatMap((group) => {
            return group.ids
          }),
        ),
      )
      if (filter.length > 0) {
        q = gql`
          ${REGIONAL_AGGS_FRAGMENT}
          query RegionsByIDAggs($ids: [ID!]! $filter: [CSearchTerm!]) {
            RegionsByID(ids: $ids) {
              ...RegionalAggsFragment
              ${aggName}(filter: $filter)
            }
          }
        `
      } else {
        q = gql`
          ${REGIONAL_AGGS_FRAGMENT}
          query RegionsByIDAggs($ids: [ID!]! ) {
            RegionsByID(ids: $ids) {
              ...RegionalAggsFragment
              ${aggName}
            }
          }
        `
      }
      getAggs({query: q, variables: {ids: getIds, filter}})
        .then(({data: {RegionsByID}}) => {
          const reducedRegions = groups
            .map((group) => {
              return regionReducer(group, RegionsByID.filter((region) => group.ids.includes(region.id)))
            })

          const final = reducedRegions
            .map((region) => ({
              id: region.id,
              statValue: region[aggName].count,
            }))
          setRegions(final)
          // const groupedRegionData =
        })
    } else {
      if (filter.length > 0) {
        q = gql`
          ${REGIONAL_AGGS_FRAGMENT}
          query AllRegionAggs($type: String!, $filter: [CSearchTerm!]) {
            AllRegions(type: $type) {
              ...RegionalAggsFragment
              ${aggName}(filter: $filter)
            }
          }
        `
      } else {
        q = gql`
          ${REGIONAL_AGGS_FRAGMENT}
          query AllRegionAggs($type: String!) {
            AllRegions(type: $type) {
              ...RegionalAggsFragment
              ${aggName}
            }
          }
        `
      }
      getAggs({ query: q, variables: { type: visType, filter } }).then(
        ({ data: { AllRegions = [] } }) => {
          if (AllRegions && AllRegions.length > 0) {
            setRegions(
              AllRegions.map((region) => ({
                id: region.id,
                statValue: region[aggName].count,
              })),
            )
          }
        },
      )
    }
  }, [visType, popType, search])

  useEffect(() => {
    const tots = regions
      .map((region) => {
        const val = region.statValue
        return Number(`${val}`)
      })
      .sort((a, b) => {
        if (a > b) {
          return 1
        } else if (b > a) {
          return -1
        }
        return 0
      })

    setAggCalcs([median(tots), percentile(5, tots), percentile(95, tots)])

    setAggRange([Math.floor(tots.shift()), Math.ceil(tots.pop())])
  }, [regions])

  return (
    <>
      <TerritoryVisualiser
        range={aggRange}
        calcs={aggCalcs}
        search={search}
        visType={visType}
      />
      <MapKey name={'Range'} range={aggRange} />
      <Pane name={'everything-else'} style={{ zIndex: 500 }}>
        <ChloroplethLayer
          calcs={aggCalcs}
          range={aggRange}
          regions={regions}
          onClick={onTerritoryClick}
          selectedTerritories={selectedAreaIds}
          popType={popType}
          visType={visType}
          geoJSON={boundedGeoJSON}
          {...props}
        />
      </Pane>
      <Pane name={'user-custom-groups'} style={{ zIndex: 1000 }}>
        <CreatedGroups />
      </Pane>
      {(geoLoading || dataLoading) && (
        <span
          style={{
            display: 'block',
            position: 'absolute',
            zIndex: 501,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <CSpinner variant={'grow'} style={{ width: '5rem', height: '5rem' }} />
        </span>
      )}
    </>
  )
}

export default RegionLayer
