import React, { useState } from 'react'
import { COffcanvas, COffcanvasBody, COffcanvasHeader } from '@coreui/react-pro'
import { CButton } from '@coreui/react'
import SidebarHelp from './SidebarHelp'

const SegmentSidebar = ({
  title,
  color,
  children,
  onClear,
  ops = [],
  hideOps = false,
  actions = [],
}) => {
  const [visible, setVisible] = useState(false)

  return (
    <>
      <COffcanvas
        portal
        placement={'start'}
        visible={visible}
        onHide={() => setVisible(false)}
        className={'segment-sidebar'}
        keyboard
        scroll={false}
      >
        <COffcanvasHeader>
          <span>{title}</span>

          {onClear && typeof onClear === 'function' && (
            <CButton color={'primary'} className={'ms-2'} size={'sm'} onClick={() => onClear()}>
              Clear All
            </CButton>
          )}
        </COffcanvasHeader>
        {actions && actions.length > 0 && (
          <div className={'p-2 ps-3 pe-3 d-flex justify-content-end align-items-center'}>
            <>
              {actions.map((action, id) => (
                <React.Fragment key={`${title}-action-${id}`}>{action}</React.Fragment>
              ))}
            </>
          </div>
        )}
        {!hideOps && <SidebarHelp ops={ops} />}
        <COffcanvasBody className={'segment-sidebar-body'}>
          {visible && <>{children}</>}
        </COffcanvasBody>
        <div
          style={{
            padding: 'var(--cui-offcanvas-padding-y) var(--cui-offcanvas-padding-x)',
            textAlign: 'right',
          }}
        >
          <CButton color={'primary'} onClick={() => setVisible(false)}>
            Done
          </CButton>
        </div>
      </COffcanvas>
      <div className={'search-segment-container'}>
        <CButton
          color={'primary'}
          size={'sm'}
          className={`search-segment-toggle ${color}`}
          onClick={() => setVisible(true)}
        >
          {title}
        </CButton>
      </div>
    </>
  )
}

export default SegmentSidebar
