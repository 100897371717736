import React, {useEffect} from "react";
import {useQuery} from "@apollo/client";
import {REGION_AGGS} from "../../../../../../graphql/queries/geo";
import {
  CCard, CCardBody,
  CCardHeader,
  CCardTitle,
  CCloseButton,
  CListGroup,
  CListGroupItem,
  CPopover,
  CSpinner, CTooltip
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import {cilInfo} from "@coreui/icons-pro";
import TopThreeTreatments from "./TopThreeTreatments";

const VisualisedTerritory = ({ region, loading = false, onClose = (id) => void 0 }) => {

  if (loading) {
    return (
      <CCard className={'territory-agg'}>
        <span className={'loading-container'}>
          <CSpinner />
        </span>
      </CCard>
    )
  } else {

    const {
      id,
      territory,
      // popstats,
      location_count: { count: location_count },
      population: { count: population_total },
      // top_treatments,
    } = region

    return (
      <CCard className={'territory-agg'}>
        <CCardHeader className={'territory-agg-header'}>
          <CCardTitle>{territory?.label}</CCardTitle>
          <CCloseButton onClick={() => {
            onClose(id)
          }}/>
        </CCardHeader>
        <CListGroup flush>
          <CListGroupItem className={'d-flex justify-content-between'}>
            <span>Total Population</span>
            <span>{population_total}</span>
          </CListGroupItem>
          {/*<CListGroupItem className={'d-flex justify-content-between'}>*/}
          {/*  <span>Weighted Average Income</span>*/}
          {/*  <span>{Math.trunc(popstats.weighted_avg_income)}</span>*/}
          {/*</CListGroupItem>*/}
          <CListGroupItem className={'d-flex justify-content-between'}>
            <CTooltip
              content={'The number of locations in this region that are visible to your current subscription'}
              trigger={['hover']}
            >
            <span>
              Locations of Interest <CIcon icon={cilInfo} size={'sm'}/>{' '}
            </span>
            </CTooltip>
            <span>{location_count}</span>
          </CListGroupItem>
        </CListGroup>
        {/*<CCardBody className={'territory-agg-body'}>*/}
        {/*  <CCardTitle>Most Mentioned Treatments</CCardTitle>*/}
        {/*  <TopThreeTreatments treatments={top_treatments.slice(0, 8)}/>*/}
        {/*</CCardBody>*/}
      </CCard>
    )
  }
}

export default VisualisedTerritory
