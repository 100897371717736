import CIcon from '@coreui/icons-react'
import {
  cilAt,
  cilBook,
  cilCalculator,
  cilDescription,
  cilGroup,
  cilList,
  cilMapAlt,
  cilPuzzle,
  cilSearch,
} from '@coreui/icons-pro'
import React from 'react'
import {isFeatureFlagged} from "../../../tools/feature-flag";

const nav = [
  {
    name: isFeatureFlagged("new-territory-mapper") ? 'Dashboard' : 'Sales Execution',
    to: '/',
    icon: <CIcon icon={cilCalculator} customClassName={'nav-icon'} />,
  },
  {
    name: isFeatureFlagged("new-territory-mapper") ? 'Regional Analysis': 'Planning',
    icon: <CIcon icon={cilMapAlt} customClassName={'nav-icon'} />,
    to: isFeatureFlagged("new-territory-mapper") ? '/planning/regional-analysis' :'/planning',
    roles: isFeatureFlagged("new-territory-mapper") ? ['maps_datamanager_view']:[],
    new: !isFeatureFlagged("new-territory-mapper"),
  },
  {
    name: 'Team Lists',
    to: '/lists',
    icon: <CIcon icon={cilList} customClassName={'nav-icon'} />,
  },
  {
    name: 'Glossary',
    to: '/glossary',
    icon: <CIcon icon={cilBook} customClassName={'nav-icon'} />,
  },
  {
    name: 'My Account',
    to: '/myaccount',
    icon: <CIcon icon={cilAt} customClassName={'nav-icon'} />,
  },
  {
    name: 'Admin',
    to: '/manage',
    icon: <CIcon icon={cilDescription} customClassName={'nav-icon'} />,
    children: [
      {
        name: 'Manage Users',
        to: '/manage/users',
        roles: ['sub_user_manage', 'sub_user_view'],
        icon: <CIcon icon={cilGroup} customClassName={'nav-icon'} />,
      },
      {
        name: 'Build User Searches',
        to: '/manage/searches',
        roles: ['sub_search_manage'],
        icon: <CIcon icon={cilSearch} customClassName={'nav-icon'} />,
      },
    ],
  },
  {
    name: 'Territories',
    to: isFeatureFlagged("new-territory-mapper") ? '/manage/territories': "/planning/territories",
    icon: <CIcon icon={cilPuzzle} customClassName={'nav-icon'} />,
  },
]



export default nav
