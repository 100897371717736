import React, {useEffect, useRef, useState} from "react";
import {CPopover, CButton} from "@coreui/react-pro";
import {CFormInput} from "@coreui/react";
import {useMutation} from "@apollo/client";
import {CREATE_TERRITORY} from "../../../../../../graphql/queries/territory";
import eventBus from "../../../../../../tools/event-bus";
import {useEventBus} from "../../../../../../hooks/use-event-bus";
import {setTerritoryJSONCB} from "../../../../../../graphql/policies/search";
import {fiveDigitRand} from "../../../../../../tools/random";

const ConvertToTerritory = ({group, onComplete = () => void 0}) => {
  const [visible, setVisible] = useState(false)
  const [groupName, setGroupName] = useState("")
  const popOver = useRef();
  const {emit} = useEventBus("toasts")

  const [createTerritory, {loading}] = useMutation(
    CREATE_TERRITORY,
    {
      onCompleted() {
        setVisible(false)
        emit({message: `Territory "${groupName}" created!`})
        setTerritoryJSONCB(fiveDigitRand())
      },
      onError(e) {
        console.log("ERROR")
        emit({message: "Territory creation failed"})
      }
    }
  )

  const outsideContext = (e) => {
    if(!popOver.current?.contains(e.target)) {
      setVisible(false)
    }
  }

  const save = () => {
    createTerritory({
      variables: {
        name: groupName,
        codes: group.ids
      }
    })
  }

  useEffect(() => {
    document.body.addEventListener('click', outsideContext)
    return () => document.body.removeEventListener('click', outsideContext)
  }, []);

  useEffect(() => {
    setGroupName(group.name)
  }, [group]);

  return <>
    <CPopover
      ref={popOver}
      visible={visible}
      onShow={() => setVisible(true)}
      onHide={() => setVisible(false)}
      trigger={["click"]}
      content={<div className={"d-flex flex-column gap-2"}>

        <CFormInput size={"sm"} value={groupName}
                    onChange={(e) => setGroupName(e.target.value)}/>
        <div className={"d-flex justify-content-between"}>
          <CButton color={"primary"} onClick={() => save()} size={"sm"}>Save</CButton>
          <CButton color={"primary"} onClick={() => setVisible(false)} size={"sm"} variant={"outline"}>Cancel</CButton>
        </div>
      </div>}><CButton size={"sm"} color={"primary"}>Convert to
      Territory</CButton></CPopover>
  </>
}

export default ConvertToTerritory
