import React, {useEffect, useState} from 'react'
import {CButtonGroup, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CInputGroup} from "@coreui/react";

const VisControl = ({selected = "", type, title, values = [], onChange = (type, value) => void(0), disabled=false, ...props}) => {

  const [selectedLabel, setSelectedLabel] = useState("")

  useEffect(() => {
    const f = values.find((v) => v.value === selected)
    if(f && f.label) {
      setSelectedLabel(f.label)
    }
  }, [selected, values]);

  return <div className={'visualiser-control'}>
      <div style={{display: "inline-block", padding: "0.25rem 0.5rem"}}>
        {title}:
      </div>
    <span>
      <CDropdown
        {...props}
      >
        <CDropdownToggle
          size={"sm"}
          disabled={disabled}
          color={"primary"}
        >
          {selectedLabel}
        </CDropdownToggle>
        <CDropdownMenu>
          {values.map((v) => <CDropdownItem
            key={`${type}-${v.value}`}
            onClick={() => onChange(type, v.value)}
            active={selected === v.value}
            disabled={v.disabled}
          >
            {v.label}
          </CDropdownItem>)}
        </CDropdownMenu>
      </CDropdown>
    </span>
    {props.children}
  </div>
}

export default VisControl
