import React, { useCallback, useContext, useEffect, useState } from 'react'
import { MapContainer, TileLayer, ZoomControl } from 'react-leaflet'
import MapControls from './Components/MapControls'
import { GeoJSONDataContext, GeoJSONDispatchContext } from '../../../../contexts/geojson-data'
import VisualiserControls from './Components/VisualiserControls'
import InPortal from '../../../UI/InPortal'
import { useDispatch } from 'react-redux'
import { collapseMenu, expandMenu } from '../../../../redux/ui-state'

// this should be configured based on a user location setting
const preloads = [
  "postcode_district",
  "postcode_area",
]

const DataAgg = ({ aggregations, loading, ...props }) => {
  const key = window._env_.MAPTILES_KEY
  const dispatch = useDispatch()
  const {loadGeoJSON, selectedAreaIds} = useContext(GeoJSONDataContext)
  const geojsonDispatch = useContext(GeoJSONDispatchContext)
  const [style, setStyle] = useState({})

  useEffect(() => {
    dispatch(collapseMenu())
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"))
    }, 300)
    return () => {
      dispatch(expandMenu())
    }
  }, []);


  useEffect(() => {
    geojsonDispatch("SET_LIVE_GROUP_ID", "")
    preloads.forEach((type) => {
      loadGeoJSON(type)
    })
  }, []);

  const onTerritoryClick = useCallback(
    (id) => {
      geojsonDispatch("SET_AREA_ID", id)
    },
    [selectedAreaIds],
  )

  const clearTerritories = () => {
    geojsonDispatch("CLEAR_AREA_IDS")
  }

  return (
    <>
      <div className={'map-page__body'} style={style}>

        <InPortal portal={"map-tools-header"}>

          <VisualiserControls
            onLoaded={(height) => {
              setStyle({"--lower-tabs-height": `${height}px`})
              setTimeout(() => {
                window.dispatchEvent(new Event("resize"))
              }, 100)
            }}
            onTerritoryClick={onTerritoryClick}
            clearTerritories={clearTerritories}
          />
        </InPortal>


        <MapContainer
          doubleClickZoom={false}
          // attributionControl={false}
          style={{margin: '0', position: 'relative', width: '100%', height: '100%'}}
          zoom={7}
          center={[54.093622, -2.892616]}
          zoomControl={false}
        >
          <ZoomControl position={'bottomright'}/>
          <TileLayer
            attribution={
              '\u003ca href="https://www.maptiler.com/copyright/" target="_blank"\u003e\u0026copy; MapTiler\u003c/a\u003e \u003ca href="https://www.openstreetmap.org/copyright" target="_blank"\u003e\u0026copy; OpenStreetMap contributors\u003c/a\u003e'
            }
            ext={'png'}
            url={`https://api.maptiler.com/maps/dataviz/{z}/{x}/{y}.png?key=${key}`}
            zIndex={300}
            crossOrigin
          />
          <MapControls/>
        </MapContainer>
      </div>
    </>
  )
}

export default DataAgg
