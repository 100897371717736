import {gql} from "@apollo/client";

export const LIST_TERRITORY = gql`
  query ListTerritory($page: Int! = 1 $filter: [JSONObject!]) {
    ListTerritory(search: {page: $page filter: $filter}) {
      entries {
        name id codes description user {
          id name
        }
      }
      pageInfo
      totalCount
    }
  }
`

export const READ_TERRITORY = gql`
  query ReadTerritory($id: ID!) {
    ReadTerritory(id: $id) {
      id name codes description user {
         id name
      }
      createdat
    }
  }
`

export const CREATE_TERRITORY = gql`
  mutation CreateTerritory($name: String! $codes: [String!]! $description: String) {
    CreateTerritory(codes: $codes name: $name description: $description) {
      id name codes description createdat user {
        id name
      }
    }
  }
`

export const UPDATE_TERRITORY = gql`
  mutation UpdateTerritory($id: ID! $name: String! $codes: [String!]! $description: String) {
    UpdateTerritory(name: $name codes: $codes id: $id description: $description) {
      id name codes description
    }
  }
`

export const DELETE_TERRITORY = gql`
  mutation DeleteTerritory($id: ID!) {
    DeleteTerritory(id: $id)
  }
`

export const TERRITORIES_FOR_SELECTED = gql`
  query TerritoriesForSelected($selected: [String!]!) {
    TerritoriesForSelected(selected: $selected)
  }
`
