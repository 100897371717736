import {gql} from "@apollo/client";

export const AREAS_IN_VIEW = gql`
  query areasWithinView($level: Int! $view: [[Float!]!]! $codes: [String!] $filter: [String!]) {
    geoAreasWithinView(level: $level view: $view codes: $codes filter: $filter) {
      data {id code geojson level label}

    }
  }
`

export const AREAS_BY_CODE = gql`
  query areasByCode($codes: [String!]!) {
    geoAreasByCode(codes: $codes) {
      id code level label
    }
  }
`

export const REGIONAL_AGGS_FRAGMENT = gql`
  fragment RegionalAggsFragment on RegionalAggregate{
    id
#    territory {
#      label
#    }
#    geojson
  }
`

export const REGION_AGGS = gql`
  query RegionAggs($id: ID!) {
    RegionalAggregate(id: $id) {
      id
      territory {
        label area_type area_code
      }
      popstats {
        population_total households_total normalised_households_total population_density normalised_population_density normalised_population_total normalised_avg_income weighted_avg_income
      }
      population_density
      population
      top_treatments
      location_count
    }
  }
`

export const REGION_AGGS_BY_IDS = gql`
  query RegionAggsByIds($ids: [ID!]! $filter: [CSearchTerm!]) {
    RegionsByID(ids: $ids) {
      id
      territory {
        label area_type area_code
      }
      popstats {
        population_total households_total normalised_households_total population_density normalised_population_density normalised_population_total normalised_avg_income weighted_avg_income
      }
#      population_density
      population
      top_treatments
      location_count(filter: $filter)
    }
  }
`
