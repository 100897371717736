import React, { useEffect, useState } from 'react'
import { AppFooter, AppHeader } from '../components'
import { Outlet, useLocation } from 'react-router-dom'
import { CContainer } from '@coreui/react'
import GenericErrorBoundary from "../components/Utility/GenericErrorBoundary";

const DefaultLayout = () => {
  const hideOn = ['view=map', 'view=data-visualiser']
  const { search, pathname } = useLocation()
  const [collapse, setCollapse] = useState(false)

  useEffect(() => {
    // This is bad and needs a better solution
    if (
      (search && search.length > 0 && search.includes('view=')) ||
      (pathname && pathname.includes('sales-targeting'))
    ) {
      setCollapse(true)
    } else {
      setCollapse(false)
    }
  }, [search, pathname])

  return (
    <>
      <div className={'wrapper d-flex flex-column min-vh-100 bg-light'}>
        <AppHeader collapse={collapse} />
        <div className={'body flex-grow-1 px-3'}>
          <CContainer fluid>
            <GenericErrorBoundary>
            <Outlet />
            </GenericErrorBoundary>
          </CContainer>
        </div>
        <AppFooter collapse={collapse} />
      </div>
    </>
  )
}

export default DefaultLayout
