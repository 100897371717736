import React, { useEffect, useState } from 'react'
import { colourFromPop } from './ChloroplethLayer/tools'

const points = []

const MapKey = ({name, range = [0,1]}) => {
  const [top, setTop] = useState(0)
  const [bottom, setBottom] = useState(0)

  useEffect(() => {
    if (range && !Number.isNaN(range[0])) {
      const b = Math.floor(range[0] / 10) * 10
      const t = Math.ceil(range[1] / 10) * 10
      setBottom(b)
      setTop(t)
    }
  }, [range]);
  return <div className={'map-page-key'} style={{width: "6rem"}}>
      <div className={"d-flex flex-column align-items-center w-100"}>
    <h5>{name}</h5>
        <div>{top}</div>
      <div className={"border border-primary"} style={{
        width: "1.5rem",
        height: "6rem",
        marginLeft: 0,
        background: `linear-gradient(${colourFromPop(1)}, ${colourFromPop(0.1)})`
      }}>
      </div>
        <div style={{marginTop: "auto"}}>{bottom}</div>
      </div>
    <div
      className={"mt-1 d-flex justify-content-between"}
      style={{padding: "0!important"}}
    >
      <div style={{width: "1rem", height: "1rem", backgroundColor: colourFromPop(-1)}} className={"border"}></div>
      <div>No Data</div>
    </div>
  </div>
}

export default MapKey
