import React, {useContext, useEffect, useRef, useState} from "react";
import {CButton, CCloseButton, CPagination, CPopover, CTable, CTooltip} from "@coreui/react";
import {CSmartPagination, CSmartTable} from "@coreui/react-pro";
import CIcon from "@coreui/icons-react";
import {cilInfo} from "@coreui/icons-pro";
import CreateRegionGroup from "./CreateRegionGroup";
import L from 'leaflet'
import {GeoJSONDataContext} from "../../../../../../contexts/geojson-data";
import UpdateRegionGroup from "./UpdateRegionGroup";

const itemsPerPage = 15

const TerritoryList = ({loading = false, regions, onRemove, onSelect = (id) => void 0, clear = () => void 0, showCreateGroup = false}) => {
  const [page, setPage] = useState(1)
  const [pages, setPages] = useState(0)
  const prevPages = useRef(0)

  const {
    liveGroupId
  } = useContext(GeoJSONDataContext)

  useEffect(() => {
    if(regions && regions.length > 0) {
      const p = Math.ceil(regions.length / itemsPerPage)
      setPages(p)
    }
  }, [regions])

  useEffect(() => {
    if(prevPages.current > pages) {
      if(page > pages) {
        setPage(pages)
      }
    }
    prevPages.current = pages
  }, [pages, regions]);

  const columns = [
    {
      key: "id",
      label: <CTooltip
      content={'Click a region name to reveal detailed information about that region.'}
      trigger={['hover']}
    ><span>Region <CIcon icon={cilInfo} size={'sm'} /></span></CTooltip>,
      _style: {
        minWidth: "6rem",
      }
    },
    {
      key: "locations",
      label: <CTooltip
        content={'The locations in each region that are visible to your subscription.'}
        trigger={['hover']}
      ><span>Locations <CIcon icon={cilInfo} size={'sm'} /></span></CTooltip>
    },
    {
      key: "remove",
      label: ""
    }
  ]

  const locationTotal = (regions) => {
    return regions.reduce((pop, region, k) => {
      return pop + region.location_count.count
    }, 0)
  }

  return (
    <>
      <div
        ref={(ref) => {
          if (!ref) {
            return void 0
          }
          L.DomEvent.disableClickPropagation(ref).disableScrollPropagation(ref)
        }}
        className={`territory-aggs-list minimal ${showCreateGroup ? " with-footer" : ""} p-2`}
      >
        <div className={"territory-aggs-list-header"}>

          <h5>Combined Statistics</h5>
          <div className={"d-flex justify-content-between align-items-center p-2"}>
            <span>Total Locations: {locationTotal(regions)}</span>
            <CButton color={"primary"} size={"sm"} onClick={() => clear()}>Clear</CButton>
          </div>
        </div>
        <div className={"territory-aggs-list-body"}>
          <CSmartTable
            loading={loading}
            small={"true"}
            itemsPerPage={15}
            pagination={false}
            activePage={page}
            items={regions}
            columns={columns}
            scopedColumns={{
              id: (item) => <td>
              <span style={{cursor: "pointer"}} className={"text-decoration-underline link-body-emphasis"}
                    onClick={() => {
                      const id = `${item.territory.area_type.toUpperCase()}/${item.territory.area_code.toUpperCase()}`
                      onSelect(id)
                    }
                    }>{item.territory.label}</span>
              </td>,
              locations: (item) => <td>{item.location_count.count}</td>,
              remove: (item) => <td><span onClick={() => {
                const id = `${item.territory.area_type.toUpperCase()}/${item.territory.area_code.toUpperCase()}`
                onRemove(id)
              }}><CCloseButton/></span></td>
            }}
          />
        </div>
        <div className={"territory-aggs-list-footer pb-1 pt-1"}>
          {pages > 1 && <CSmartPagination
            align={"center"}
            size={"sm"}
            activePage={page}
            pages={pages}
            onActivePageChange={(p) => setPage(p)}
          />}
          {showCreateGroup && !liveGroupId && <div className={"text-center"}><CreateRegionGroup regions={regions} clear={clear}/></div>}
          {showCreateGroup && liveGroupId && <div className={"text-center"}><UpdateRegionGroup id={liveGroupId} regions={regions} clear={clear} /></div> }
        </div>
      </div>
    </>
  )
}

export default TerritoryList
