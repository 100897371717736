import React, { useCallback, useEffect, useState } from 'react'
import {FeatureGroup, GeoJSON, Popup} from 'react-leaflet'
import {useDispatch, useSelector} from 'react-redux'
import {clearActive, loadGeoJSONByType, rerollCacheBuster, setActive} from "../../../../../../redux/geojson";
import DeleteTerritory from "../TerritoryList/DeleteTerritory";
import EditTerritory from "../TerritoryList/EditTerritory";
import {useEventBus} from "../../../../../../hooks/use-event-bus";
import {v4} from "uuid"

const TerritoryShapes = ({ selectedAreaIds = [] }) => {
  const isEditing = useSelector(({ territoryReducer }) => territoryReducer.edit)
  const geoJSON = useSelector(({ geojsonReducer }) => geojsonReducer.geoJSON)
  const [finalGeoJSON, setFinalGeoJSON] = useState(undefined)
  const [keyHash, setKeyHash] = useState("")
  const territoryID = useSelector(({ territoryReducer }) => territoryReducer.id ?? '')
  const [activeID, setActiveID] = useState("")
  const dispatch = useDispatch()
  const {emit: mapEmit} = useEventBus("map-actions", ({detail: {action, value}}) => {
    if(action === "setActiveID") {
      setActiveID(value)
    }
  })
  const {emit} = useEventBus("territory-refresh")

  useEffect(() => {
    if (geoJSON && geoJSON?.user_territory) {
      const json = {
        ...geoJSON.user_territory,
        features: geoJSON.user_territory.features.filter(
          (feature) => feature.id !== `USER_TERRITORY/${territoryID.toUpperCase()}`,
        ),
      }
      setFinalGeoJSON(json)
      // setTerritoryIDs(json.features.map((feature) => feature.id))
    }
    setKeyHash(v4())
  }, [geoJSON, territoryID])

  const style = useCallback(
    (feature) => {
      const {
        properties: { territory_id },
      } = feature
      return {
        fillOpacity: territory_id === activeID ? 0.7 : 0.2,
      }
    },
    [activeID],
  )

  if (!finalGeoJSON) {
    return <></>
  }

  return (
    <>
      <FeatureGroup
        key={keyHash}
      >
        <>{finalGeoJSON.features
          .filter((feature) => territoryID !== feature.id)
          .map((feature) => {
          const [,id] = feature.id.toLowerCase().split("/")
          return <GeoJSON
            key={`geojson-${id}-${keyHash}`}
            data={feature}
            style={style}
            onEachFeature={(feature, layer) => {
              const {
                id,
              } = feature


              if (selectedAreaIds.includes(id)) {
                layer.bringToFront()
              } else {
                layer.bringToBack()
              }

              layer.on('mouseover', (e) => {
                if(!isEditing) {
                  mapEmit({value: id, action: "setActiveID"})
                }
              })
              layer.on("mouseout", () => {
                mapEmit({value: "", action: "setActiveID"})
              })
            }}
          >
            {!isEditing && <Popup style={{zIndex: 10000}} pane={'popups'}>
              <h5>{feature.properties.name}</h5>
              <div className={"d-flex gap-2"}>
                <EditTerritory id={id} />
                <DeleteTerritory
                  id={id}
                  onComplete={() => {
                    dispatch(rerollCacheBuster())
                    dispatch(loadGeoJSONByType("user_territory"))
                    emit("territory-refresh")
                  }}
                />
              </div>
            </Popup>}
          </GeoJSON>
        } )}</>
      </FeatureGroup>
    </>
  )
}

export default TerritoryShapes
