import React, {useCallback, useContext, useEffect, useState} from 'react'
import {GeoJSONDataContext, GeoJSONDispatchContext} from '../../../../../contexts/geojson-data'
import {FeatureGroup, GeoJSON, useMapEvents} from 'react-leaflet'
import geoJSONBounds from '../../../tools/geojson-bounds'
import ChloroplethLayer from "../../DataAgg/Components/ChloroplethLayer";
import {useDispatch, useSelector} from "react-redux";
import {addCodes, editStatus, removeCode, territorySlice, toggleCode} from "../../../../../redux/territory";
import Chloropleth from "./Chloropleth";
import {useEventBus} from "../../../../../hooks/use-event-bus";
import {v4} from 'uuid'

const SelectionShapes = () => {
  const dispatch = useDispatch()
  const geoJSON = useSelector(({geojsonReducer}) => geojsonReducer.geoJSON)
  const viewType = useSelector(({geojsonReducer}) => geojsonReducer.viewType)
  const [finalGeoJSON, setFinalGeoJSON] = useState(undefined)
  const [bounds, setBounds] = useState({})
  const [activeID, setActiveID] = useState("")
  const [keyHash, setKeyHash] = useState("")
  const [selectedAreas, setSelectedAreas] = useState([])

  const isEditing = useSelector(({territoryReducer}) => territoryReducer.edit)
  const selectedAreaIds = useSelector(({territoryReducer}) => territoryReducer.codes)

  useEventBus("map-actions", ({detail: {action, value}}) => {
    if(action === "setActiveID") {
      setActiveID(value)
    }
  })

  const map = useMapEvents({
    moveend(e) {
      setBounds(e.target.getBounds())
    },
  })

  useEffect(() => {
    if (map) {
      setBounds(map.getBounds())
    }
  }, [map])

  useEffect(() => {
    console.log("VIEWTYPE", viewType)
    if (geoJSON && geoJSON[viewType]) {
      // console.log(viewType, geoJSON[viewType])
      setFinalGeoJSON(geoJSON[viewType])
    }

    const s = selectedAreaIds.map((id) => {
      const [type,] = id.toLowerCase().split("/")
      if(geoJSON[type]) {
        return geoJSON[type].features.find((feature) => feature.id === id)
      } else {
        return undefined
      }
    })
      .filter((f) => typeof f !== "undefined")

    console.log(s)

    setSelectedAreas(s)

  }, [geoJSON, isEditing, selectedAreaIds, viewType])

  useEffect(() => {
      const h = v4()
      setKeyHash(h)
  }, [finalGeoJSON, selectedAreas]);

  const territoryClick = (id) => {
    if(isEditing) {
      dispatch(toggleCode(id))
    }
  }

  if (!finalGeoJSON) {
    return <></>
  }

  return (
    <>
      <Chloropleth
        geoJSON={finalGeoJSON}
        selectedAreaIDs={selectedAreaIds}
        activeID={activeID}
        onClick={(id) => territoryClick(id)}
        freeze={isEditing === false}
        hideSelected
      />
      <FeatureGroup
        key={keyHash}
        pane={'area-shapes'}
      >
        <>{selectedAreas.map((area) => {
          return <GeoJSON
            key={`${keyHash}-${area.id}-${isEditing}`}
            data={area}
            onEachFeature={(feature, layer) => {
              layer.bringToBack()
              layer.on('click', () => {
                dispatch(removeCode(feature.id))
              })
            }}
          ></GeoJSON>
        })}</>
      </FeatureGroup>

      {/*<ChloroplethLayer*/}
      {/*  regions={[0]}*/}
      {/*  geoJSON={finalGeoJSON}*/}
      {/*  selectedTerritoryID={activeId}*/}
      {/*  range={[0,0]}*/}
      {/*  calcs={[0,0,0]}*/}
      {/*  selectedTerritories={selectedAreaIds}*/}
      {/*  onClick={(id) => void territoryClick(id)}*/}
      {/*/>*/}
    </>
  )
}

export default SelectionShapes
